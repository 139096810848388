let step1InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
  hide: boolean;
}[] = [
  {
    key: "1_1315a5",
    input_id: 0,
    input_type: 1, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.5 Asigna una finca o fábrica",
    options: ["Finca 1", "Fabrica 1"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Selecciona tu finca",
    hide: true,
  },
  {
    key: "subCompanyId",
    input_id: 1,
    input_type: 1, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "Razón social relacionada",
    options: ["Razon social 1", "Razon social 2"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Selecciona tus razones sociales",
    hide: true,
  },
  {
    key: "rol1",
    input_id: 2,
    input_type: 1, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "Selecciona tu rol para esta regulación",
    options: ["Rol 1", "Rol 2"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccionar roles a regular",
    hide: true,
  },
  {
    key: "1_1320a",
    input_id: 3,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "Número de Lote (solo si empaca, transforma o Recibe en tierra de buque)",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe el número de tu lote",
    hide: false,
  },
  {
    key: "dispatch1",
    input_id: 4,
    input_type: 1, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "SOLO Despachadores: ¿Empacó antes de despachar? ",
    options: ["Si", "No"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Si o No",
    hide: false,
  },
];

export default step1InputsArray;

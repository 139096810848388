import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Hidden,
} from '@mui/material';
import { useAuth } from '../../context/authContext';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useNavigate } from 'react-router-dom';

const LatestMovements = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useAuth();
  const [movement, setMovement] = useState([]);
  const companyId = auth?.authState.user?.company;

  useEffect(() => {
    fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/form-applications/company/${companyId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth?.authState.token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setMovement(data))
      .catch((error) => {
        console.error('Error fetching movements:', error);
      });
  }, [companyId, auth?.authState.token]);

  const last10Movements = movement.slice(0, 10);
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'REJECTED':
        return '#CF2828';
      case 'INPROGRESS':
        return '#4086C3';
      case 'PENDING':
        return '#FFD700';
      case 'FINISHED':
        return '#FFA500';
      case 'APPROVED':
        return '#01964E';
      default:
        return 'default';
    }
  };

  const translateStatus = (status: string) => {
    switch (status) {
      case 'REJECTED':
        return 'Rechazada';
      case 'INPROGRESS':
        return 'En progreso';
      case 'PENDING':
        return 'Pendiente';
      case 'FINISHED':
        return 'Finalizada';
      case 'APPROVED':
        return 'Aprobada';
      default:
        return 'default';
    }
  };

  return (
    <Card>
      <CardContent>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          gap={10}
        >
          <Typography variant='h6' color='#093BA0'>
            ÚLTIMOS MOVIMIENTOS
          </Typography>
          <Hidden smDown>
            <Button
              variant='text'
              sx={{ color: '#093BA0' }}
              startIcon={<AddBoxOutlinedIcon />}
              onClick={() => navigate('/traceability')}
            >
              Ver todos
            </Button>
          </Hidden>
        </Box>
        <TableContainer style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {last10Movements.length > 0 ? (
                last10Movements.map((movement: any, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderBottom: 'none',
                        fontSize: '1.2rem',
                        [theme.breakpoints.down('sm')]: {
                          fontSize: '0.8rem',
                        },
                      }}
                    >
                      <b>Lote #${movement['1_1320a']}</b>
                      {`, ${
                        movement?.companyProduct?.regulatedProduct?.name
                      }, Estados Unidos,  ${movement?.createdAt?.slice(0, 10)}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: getStatusColor(
                          movement?.companyProduct?.status
                        ),
                        color: 'white',
                        borderRadius: '20px',
                        height: '1px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 1,
                        borderBottom: 'none',
                        [theme.breakpoints.down('sm')]: {
                          mt: 2.5,
                          height: '1px',
                          fontSize: '0.8rem',
                        },
                      }}
                    >
                      {translateStatus(movement?.companyProduct?.status)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    Aún no hay movimientos registrados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default LatestMovements;

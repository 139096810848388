import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Button, Paper, Typography, Box } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { ProductListItem } from './ProductListItem';

interface ProductData {
  id: number;
  block: string;
  variety: string;
  roles: string[];
  loteCodeRule: null | string;
  sproutsDescription: null | string;
  regulatedProduct: {
    id: number;
    name: string;
    variety: string;
    imageUrl: string;
  };
}

export const ProductList = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [products, setProducts] = useState<ProductData[]>([]);
  const companyId = auth?.authState.user?.company;

  useEffect(() => {
    fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/companyProducts/company/${companyId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth?.authState.token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setProducts(data))
      .catch((error) => {
        //TODO:Manejar el error
        console.error('Error fetching farms:', error);
      });
  }, [companyId, auth?.authState.token]);

  return (
    <Paper
      sx={{
        maxWidth: 800,
        maxHeight: 600,
        mx: 'auto',
        p: 2,
        backgroundColor: '#F4F4F4',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexwrap: 'nowrap',
        }}
      >
        <Typography variant='h6' color='#093BA0'>
          MIS PRODUCTOS
        </Typography>
        <Button
          startIcon={<EditOutlined />}
          size='small'
          sx={{ color: '#093BA0', ml: 2 }}
          onClick={() => navigate('/products')}
        >
          Editar
        </Button>
      </Box>
      <List sx={{ width: '100%' }}>
        {products.length > 0 ? (
          products.map((product: ProductData) => (
            <ProductListItem
              key={product.id}
              name={product.regulatedProduct.name}
            />
          ))
        ) : (
          <Typography variant='subtitle1'>
            Aún no hay Productos registrados
          </Typography>
        )}
      </List>
    </Paper>
  );
};

/* eslint-disable array-callback-return */
import * as React from "react";
import styles from "./ApplicationStep5.style.module.css";
import { useState } from "react";
import { StandardInput } from "../../reusables/StandardInput/StandardInput";
import { StandardButton } from "../../reusables/StandardButton/StandardButton";
import { StandardCombobox } from "../../reusables/StandardCombobox/StandardCombobox";
import { SecondaryButton } from "../../reusables/SecondaryButton/SecondaryButton";
import { StandardMultipleOptionsInput } from "../../reusables/StandardMultipleOptionsInput/StandardMultipleOptionsInput";
import { CalendarInput } from "../../reusables/CalendarInput/CalendarInput";
import { useParams } from "react-router-dom";

interface ApplicationStep5Props {
  setStep: (value: number) => void;
  step5InputsArray: any[];
  productData: any;
}

export const ApplicationStep5 = ({
  setStep,
  step5InputsArray,
  productData,
}: ApplicationStep5Props) => {
  const { productId } = useParams();
  const setValueInArray = (inputValue: string, arrayPosition: number) => {
    step5InputsArray[arrayPosition].input_value = inputValue;
  };

  const setValueInArrayForCalendars = (inputValue: any, arrayPosition: number) => {

    const currentTime = new Date(inputValue)
    step5InputsArray[arrayPosition].input_value = currentTime.toISOString();

    let cont = 0;
    // step3InputsArray[arrayPosition].prefillArrayNumber.map((value: number) => {
    //   preFiller(
    //     value,
    //     step2InputsArray[arrayPosition].prefillArrayNumber[cont],
    //     // inputValue
    //     currentTime.toISOString()
    //   );
    // });
  };

  const setMultipleValuesInArray = (
    inputValue: string[],
    arrayPosition: number
  ) => {
    step5InputsArray[arrayPosition].input_multiple_values = inputValue;
  };

  const findInStringArray = (strArray: string[], value: string) => {
    let result = false;
    strArray?.map((str: string) => {
      if (str === value) result = true;
    });
    return result;
  };
  const findPreviousStep = () => {
    if (findInStringArray(productData?.roles, "Empaca")) {
      if (findInStringArray(productData?.roles, "Brote")) {
        setStep(4.1);
        return;
      } else {
        setStep(4);
        return;
      }
    }
    if (findInStringArray(productData?.roles, "Enfría")) {
      setStep(3);
      return;
    }
    if (findInStringArray(productData?.roles, "Cosecha")) {
      setStep(2);
      return;
    }
    setStep(1);
    return;
  };

  const findNextStep = () => {
    if (findInStringArray(productData?.roles, "Despacha")) {
      setStep(6);
      return;
    }
    if (findInStringArray(productData?.roles, "Recibe")) {
      setStep(7);
      return;
    }
    if (findInStringArray(productData?.roles, "Transformador")) {
      setStep(8);
      return;
    }
    //FINAL STEP
    setStep(99);
    return;
  };

  const ArrayPrinter = () => {
    //la variable arrayOfInputs tendra un conjunto de HTMLS en cada posicion del arreglo y cada uno de ellos sera un input imprimido dinamicamente, mediante lo que este en el "inputsArray.tsx de la misma carpeta que este archivo"
    let arrayOfInputs: any = [];
    const [, /*value*/ setValue] = useState("");
    step5InputsArray.map((index: any) => {
      if (index.input_type === 0) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <StandardInput
              setValue={setValue}
              upperText={index.upper_text}
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              label={index.input_label}
              defaultValue={index.input_value}
            />
          </div>
        );
      } else if (index.input_type === 1) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <StandardCombobox
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              upperText={index.upper_text}
              options={index.options}
              label={index.input_label}
              defaultValue={index.input_value}
            />
          </div>
        );
      } else if (index.input_type === 2) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            {productId === "0" ? (
              <StandardInput
                setValue={setValue}
                upperText={index.upper_text}
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, index.input_id)
                }
                arrayPosition={index.input_id}
                label={index.input_label}
                defaultValue={index.input_value.slice(0, 10)}
              />
            ) : (
              <CalendarInput
                setValueInArray={(value: string, position: number) =>
                  setValueInArrayForCalendars(value, index.input_id)
                }
                arrayPosition={index.input_id}
                upperText={index.upper_text}
                defaultValue={index.input_value}
              />
            )}
          </div>
        );
      } else if (index.input_type === 3) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <StandardMultipleOptionsInput
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              setMultipleValuesInArray={(value: string[], position: number) =>
                setMultipleValuesInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              upperText={index.upper_text}
              defaultValue={index.input_multiple_values}
              inputOptions={index.options}
              inputLabel={index.input_label}
            />
          </div>
        );
      }
    });

    return arrayOfInputs;
  };

  return (
    <>
      <div className={styles.step1Container}>
        <h2 className={styles.titleText}>Regulación</h2>
        <p className={styles.subtitleText}>
          Ingresa una nueva solicitud sobre tu Lote
        </p>
        <div className={styles.stepInformationContainer}>
        <h2 className={styles.informationMainTitleText}>Datos de receptor terrestre de buque</h2>
          <p className={styles.informationTitleText}>
            Ingresa los datos relacionados a:{" "}
            <i>{productData?.regulatedProduct?.name}</i>
          </p>
          <div className={styles.inputsContainer}>{ArrayPrinter()}</div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              executableFunction={() => {
                if (productId === "0") {
                  setStep(4);
                } else {
                  findPreviousStep();
                }
              }}
              innerText="Atrás"
            />
            <StandardButton
              executableFunction={() => {
                if (productId === "0") {
                  setStep(6);
                } else {
                  findNextStep();
                }
              }}
              innerText="Siguiente"
            />
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { HomeNavbar } from "../components/HomeNavbar";
import { Box, Button, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import UserData from "../components/profile/UserData";
import CompanyData from "../components/profile/CompanyData";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../context/authContext";

export const Profile: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, m: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} marginBottom={10}>
          <HomeNavbar />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            component="h1"
            alignItems="center"
            textAlign="center"
          >
            Mi Perfil
          </Typography>
          <Typography
            variant="h6"
            component="h1"
            alignItems="center"
            textAlign="center"
            color="#A6A6A6"
          >
            Mantén actualizados tus datos y los de la empresa
          </Typography>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <UserData />
        </Grid>
        <Grid item xs={12}>
          <CompanyData />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1, m: 2 }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: "20px",
                color: "#093BA0",
                fontWeight: "bold",
              }}
            >
              Plan de Trazabilidad
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, m: 2 }}>
            <Button
              variant="contained"
              onClick={() => navigate("/traceabilityPlan")}
              sx={{
                mt: 1,
                borderRadius: "30px",
                backgroundColor: "#093BA0",
                color: "#FFFFFF",
                paddingX: "30px",
                textTransform: "none",
                fontSize: "1.1rem",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#093BA0",
                },
              }}
            >
              Completar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1, m: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                auth.logoutUser();
                navigate("/");
              }}
              sx={{
                mt: 1,
                borderRadius: "30px",
                backgroundColor: "#093BA0",
                color: "#FFFFFF",
                paddingX: "30px",
                textTransform: "none",
                fontSize: "1.1rem",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#093BA0",
                },
              }}
            >
              Cerrar sesión <LogoutIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

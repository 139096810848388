import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import { Register } from "./pages/register";
import { AuthProvider, useAuth } from "./context/authContext";
import { SnackbarProvider } from "./context/snackbarContext";
import { Menu } from "./pages/Menu";
import { Home } from "./pages/Home";
import { Farm } from "./pages/Farm";
import { Product } from "./pages/Product";
import { Profile } from "./pages/Profile";
import { Application } from "./pages/Application";
import { Traceability } from "./pages/Traceability";
import { TraceabilityPlan } from "./components/traceabilityPlan/traceabilityPlan";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ValidateLocalStorage = (auth: any) => {
  return auth
    .ValidateIfUserIsAuthenticated()
    .then((res: boolean) => res)
    .catch((error: boolean) => false);
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const auth = useAuth();
  if (!auth || !auth.authState.isAuthenticated) {
    ValidateLocalStorage(auth) ? <Navigate to="/login" /> : <Navigate to="/" />;
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<h1>Not Found</h1>} />
            <Route
              path="/menu"
              element={
                <ProtectedRoute>
                  <Menu />
                </ProtectedRoute>
              }
            />
            <Route
              path="/farms"
              element={
                <ProtectedRoute>
                  <Farm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute>
                  <Product />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/application/:productId/:formPrefill/:companyProductID"
              element={
                <ProtectedRoute>
                  <Application />
                </ProtectedRoute>
              }
            />
            <Route
              path="/traceability"
              element={
                <ProtectedRoute>
                  <Traceability />
                </ProtectedRoute>
              }
            />
            <Route
              path="/traceabilityPlan"
              element={
                <ProtectedRoute>
                  <TraceabilityPlan />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;

let step5InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
}[] = [
  {
    key: "1_1335c",
    input_id: 0,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "c. Cantidad y unidad de medida del alimento (p.ej., 200 Kilos)",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1335di",
    input_id: 1,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "di. Rango de fechas de cosecha",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1335dii",
    input_id: 2,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "dii. Ubicaciones de cosecha (según se identifica en el Código Oceánico del Servicio Nacional de Pesca Marina)",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el lugar según mapa",
  },
  {
    key: "1_1335e",
    input_id: 3,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "e. Descripción del lugar para el primer receptor terrestre",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba descripción",
  },
  {
    key: "1_1335f",
    input_id: 4,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "f. Fecha de desembarco del alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione fecha",
  },
  {
    key: "1_1335g",
    input_id: 5,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "g. Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
  },
];

export default step5InputsArray;

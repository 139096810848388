import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { HomeNavbar } from '../components/HomeNavbar';
import { FarmList } from '../components/farm/FarmList';
import { ProductList } from '../components/product/ProductList';
import LatestMovements from '../components/notifications/MenuNotificationsTable';

export const Menu: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1, m: 5 }}>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={12}>
          <HomeNavbar />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center', mt: 10, mb: 3 }}>
            <Typography
              variant='h3'
              component='h1'
              color='#093BA0'
              fontWeight='bold'
            >
              FOOD SAFETY KEY
            </Typography>
            <Typography variant='h6' color='#A6A6A6'>
              Bienvenido a tu dashboard
            </Typography>
          </Box>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={8}
          justifyContent='center'
          alignItems='center'
        >
          <Box
            sx={{
              textAlign: 'center',
              mt: 3,
              mb: 3,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h6'
              color='#093BA0'
              fontWeight='bold'
              textAlign='left'
              pl={2}
            >
              Notificaciones
            </Typography>
            <Box sx={{ alignItems: 'center', justifyContent: 'center', m: 2 }}>
              <LatestMovements />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 5,
            }}
          >
            <FarmList />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 5,
            }}
          >
            <ProductList />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

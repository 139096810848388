import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAuth } from '../../context/authContext';
import { useSnackbar } from '../../context/snackbarContext';

interface RequestData {
  id: string;
  email: string;
  password?: string;
  oldPassword?: string;
  companyId: string;
}

interface UserUpdateResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  createdAt: string;
  companyName: string;
}


const UserData: React.FC = () => {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>(auth?.authState.user?.email || '');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isSubmmiting, setIsSubmmiting] = useState<boolean>(false);
  const userId = auth?.authState.user?.id;
  const companyId = auth?.authState.user?.company;
  const token = auth?.authState.token;

  const cleanForm = (newEmail?: string) => {
    setEmail(newEmail || auth?.authState.user?.email || '');
    setCurrentPassword('');
    setNewPassword('');
    setIsEmailValid(true);
    setIsPasswordValid(true);
  }

  const handleEdit = () => {
    setIsEditing(true);
  }

  const handleCancel = () => {
    setIsEditing(false);
    cleanForm();
  }

  //TODO: Move validation to a helper file
  //Regex for email validation
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    //Check if the email is valid
    setIsEmailValid(emailRegex.test(newEmail));
  }

  //TODO: Move validation to a helper file
  //Regex for password validation with at least 1 uppercase letter and 8 characters
  const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setNewPassword(newPassword);
    //Check if the password is valid
    setIsPasswordValid(passwordRegex.test(newPassword));

    if (newPassword === '')
      setIsPasswordValid(true);
  }

  //Handle the form submission
  const handleSubmit = async () => {
    const newPasswordEntered = newPassword !== '';

    let requestData: RequestData = {
      id: userId || '',
      email: email,
      companyId: companyId || '',
    };

    if (newPasswordEntered) {
      requestData = {
        ...requestData,
        oldPassword: currentPassword,
        password: newPassword,
      }
    }

    //Call the API to update the user data
    try {
      setIsSubmmiting(true);
      const response = await fetch(`https://backend-iawdfmhnsa-uc.a.run.app/users/change-password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Error al actualizar los datos del usuario');
      }
      const data: UserUpdateResponse = await response.json();

      //Update the user data in context
      if (data.email !== auth?.authState.user?.email) {
        await auth?.updateUserEmail(data.email);
        cleanForm(data.email);
      } else {
        cleanForm();
      }
      showSnackbar('Datos actualizados correctamente', 'success');
      setIsEditing(false);
    } catch (error) {
      showSnackbar('Error al actualizar los datos del usuario', 'error');
      console.error(error);
    } finally {
      setIsSubmmiting(false);
    }
  }

  return (
    <Box sx={{ flexGrow: 1, m: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: '20px',
                color: '#093BA0',
                fontWeight: 'bold',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Datos Usuario
              {!isEditing && (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  onClick={handleEdit}
                  sx={{ ml: 1 }}
                >
                  Editar Datos
                </Button>
              )}

            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Correo Electrónico"
            value={email}
            onChange={handleEmailChange}
            disabled={!isEditing}
            error={!isEmailValid}
            helperText={!isEmailValid ? 'Por favor, ingresa un correo válido' : ''}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Contraseña Actual"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Nueva Contraseña"
            type="password"
            value={newPassword}
            onChange={handlePasswordChange}
            disabled={!isEditing}
            error={!isPasswordValid}
            helperText={!isPasswordValid ? 'La contraseña debe tener al menos 8 caracteres y una letra mayúscula' : ''}
          />
        </Grid>
        {isEditing && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <Button variant="outlined"
                sx={{
                  mr: 2,
                  mt: 1,
                  borderRadius: '30px',
                  borderColor: '#093BA0',
                  backgroundColor: 'transparent',
                  color: '#093BA0',
                  paddingX: '30px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  '&:hover': {
                    backgroundColor: '#093BA0',
                    borderColor: '#093BA0',
                    color: '#FFFFFF',
                  },
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button variant="contained"
                sx={{
                  mt: 1,
                  borderRadius: '30px',
                  backgroundColor: '#093BA0',
                  color: '#FFFFFF',
                  paddingX: '30px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#093BA0',
                  },
                }}
                onClick={handleSubmit}
                disabled={isSubmmiting || !isEmailValid || !isPasswordValid}
              >
                Guardar Cambios
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default UserData;
let step8InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
}[] = [
  {
    key: "1_1350a1iii",
    input_id: 0,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "1.iii Para cada lote de trazabilidad utilizado, la cantidad y unidad de medida del alimento utilizado de ese lote.",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1350a2i",
    input_id: 1,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "2.i Nuevo código de lote de trazabilidad",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Nombre y dirección",
  },
  {
    key: "1_1350a2ii",
    input_id: 2,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "2.ii Descripción del lugar donde transformaste el alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Dirección lugar y nombre",
  },
  {
    key: "1_1350a2iii",
    input_id: 3,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "2.iii Fecha de transformación del alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione fecha",
  },
  {
    key: "1_1350a2iv",
    input_id: 4,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "2.iv Descripción del producto ",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Describa el producto transformado",
  },
  {
    key: "1_1350a2v",
    input_id: 5,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "2.v  La cantidad y la unidad de medida del alimento (p.ej., 6 cajas, 25 contenedores de plástico reutilizables, 100 tanques, 200 libras);",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1350a2vi",
    input_id: 6,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "2.vi Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
  },
];

export default step8InputsArray;

import React, { useEffect, useState, useCallback } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SubCompanies from './SubCompany';
import { useAuth } from '../../context/authContext';
import { useSnackbar } from '../../context/snackbarContext';


interface SubCompany {
  id: number;
  subCompanyName: string;
  subCompanyRut: string;
}

interface NewSubCompany {
  subCompanyName: string;
  subCompanyRut: string;
}

interface CompanyResponse {
  id: number;
  rut: string;
  companyName: string;
  companyFantasyName: string;
  address: string;
  subCompanies: SubCompany[];
  ownerName: string;
}

interface RequestData {
  companyName: string;
  address: string;
  ownerName: string;
  subCompanies: (SubCompany | NewSubCompany)[];
}

const CompanyData: React.FC = () => {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false); //Change when connected to backend
  const [error, setError] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [ownerName, setOwnerName] = useState<string>('');
  const [officeAddress, setOfficeAddress] = useState<string>('');
  const [touched, setTouched] = useState({
    companyName: false,
    ownerName: false,
    officeAddress: false,
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [subCompanies, setSubCompanies] = useState<SubCompany[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isSubmmiting, setIsSubmmiting] = useState<boolean>(false);
  const companyId = auth?.authState.user?.company;
  const token = auth?.authState.token;

  const fetchCompanyData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/companies/${companyId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Error al obtener los datos de la empresa');
      }
      const data: CompanyResponse = await response.json();
      setOfficeAddress(data.address);
      setCompanyName(data.companyName);
      setSubCompanies(data.subCompanies);
      setOwnerName(data.ownerName);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [companyId, token]);

  useEffect(() => {
    fetchCompanyData();
  }, [fetchCompanyData]);

  const cleanForm = () => {
    fetchCompanyData();
  }

  const handleEdit = () => {
    setIsEditing(true);
  }

  const handleCancel = () => {
    setIsEditing(false);
    cleanForm();
  }

  const handleSubmit = async () => {
    const newSubCompanies: (SubCompany | NewSubCompany)[] = subCompanies.map(subCompany => {
      if (subCompany.id === 0) {
        //New subcompany without id
        const { id, ...newSubCompany } = subCompany;
        return newSubCompany;
      } else {
        return subCompany;
      }
    });

    let requestData: RequestData = {
      companyName: companyName,
      address: officeAddress,
      ownerName: ownerName,
      subCompanies: newSubCompanies,
    };

    try {
      setIsSubmmiting(true);
      const response = await fetch(`https://backend-iawdfmhnsa-uc.a.run.app/companies/${companyId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Error al actualizar los datos de la empresa');
      } else {
        cleanForm();
        setIsEditing(false);
        showSnackbar('Datos actualizados correctamente', 'success');
      }
    } catch (error: unknown) {
      showSnackbar('Error al actualizar los datos de la empresa', 'error');
    } finally {
      setIsSubmmiting(false);
    }
  }

  //Input handling
  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  }

  //TODO: Move validation to a helper file
  const isEmpty = (value: string) => !value.trim();
  const isValidRut = (rut: string) => {
    const rutRegex = /^\d+-[0-9kK]$/;
    return rutRegex.test(rut);
  }

  //Validate form
  useEffect(() => {
    const areSubCompaniesValid = subCompanies.every(subCompany => {
      return !isEmpty(subCompany.subCompanyName) && !isEmpty(subCompany.subCompanyRut) && isValidRut(subCompany.subCompanyRut);
    });
    const isFormValid = !isEmpty(companyName) &&
      !isEmpty(ownerName) &&
      !isEmpty(officeAddress) &&
      areSubCompaniesValid;

    setIsFormValid(isFormValid);
  }, [companyName, ownerName, officeAddress, subCompanies])

  return (
    <Box sx={{ flexGrow: 1, m: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontSize: '20px',
                color: '#093BA0',
                fontWeight: 'bold',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Datos generales empresa
              {!isEditing && !error && !isLoading && (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  onClick={handleEdit}
                  sx={{ ml: 1 }}
                >
                  Editar datos
                </Button>
              )}
            </Typography>
          </Box>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        {isLoading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (!error) && (
          <>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Nombre comercial"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
                onBlur={() => handleBlur('companyName')}
                disabled={!isEditing}
                error={touched.companyName && isEmpty(companyName)}
                helperText={touched.companyName && isEmpty(companyName) ? 'Campo requerido' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Nombre completo encargado"
                value={ownerName}
                onChange={e => setOwnerName(e.target.value)}
                onBlur={() => handleBlur('ownerName')}
                disabled={!isEditing}
                error={touched.ownerName && isEmpty(ownerName)}
                helperText={touched.ownerName && isEmpty(ownerName) ? 'Campo requerido' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Dirección oficina central"
                value={officeAddress}
                onChange={e => setOfficeAddress(e.target.value)}
                onBlur={() => handleBlur('officeAddress')}
                disabled={!isEditing}
                error={touched.officeAddress && isEmpty(officeAddress)}
                helperText={touched.officeAddress && isEmpty(officeAddress) ? 'Campo requerido' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <SubCompanies
                isEditing={isEditing}
                subCompanies={subCompanies}
                setSubCompanies={setSubCompanies}
              />
            </Grid>
          </>
        )}
        {isEditing && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <Button variant="outlined"
                sx={{
                  mr: 2,
                  mt: 1,
                  borderRadius: '30px',
                  borderColor: '#093BA0',
                  backgroundColor: 'transparent',
                  color: '#093BA0',
                  paddingX: '30px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  '&:hover': {
                    backgroundColor: '#093BA0',
                    borderColor: '#093BA0',
                    color: '#FFFFFF',
                  },
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button variant="contained"
                disabled={!isFormValid || isSubmmiting}
                sx={{
                  mt: 1,
                  borderRadius: '30px',
                  backgroundColor: '#093BA0',
                  color: '#FFFFFF',
                  paddingX: '30px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  '&:hover': {
                    backgroundColor: '#FFFFFF',
                    color: '#093BA0',
                  },
                }}
                onClick={handleSubmit}
              >
                Guardar Cambios
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default CompanyData;
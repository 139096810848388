/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef } from "react";

import { HomeNavbar } from "../components/HomeNavbar";
import { useAuth } from "../context/authContext";
import step1InputsArray from "../components/application/step1/InputsArray";
import step2InputsArray from "../components/application/step2/InputsArray";
import step3InputsArray from "../components/application/step3/InputsArray";
import step4InputsArray from "../components/application/step4/InputsArray";
import step4_1InputsArray from "../components/application/step4.1/InputsArray";
import step5InputsArray from "../components/application/step5/InputsArray";
import step6InputsArray from "../components/application/step6/InputsArray";
import step7InputsArray from "../components/application/step7/InputsArray";
import step8InputsArray from "../components/application/step8/InputsArray";
import { ApplicationStep1 } from "../components/application/step1/ApplicationStep1";
import { ApplicationStep2 } from "../components/application/step2/ApplicationStep2";
import { ApplicationStep3 } from "../components/application/step3/ApplicationStep3";
import { ApplicationStep4 } from "../components/application/step4/ApplicationStep4";
import { ApplicationStep4_1 } from "../components/application/step4.1/ApplicationStep4_1";
import { ApplicationStep5 } from "../components/application/step5/ApplicationStep5";
import { ApplicationStep6 } from "../components/application/step6/ApplicationStep6";
import { ApplicationStep7 } from "../components/application/step7/ApplicationStep7";
import { ApplicationStep8 } from "../components/application/step8/ApplicationStep8";
import { useParams } from "react-router-dom";
import { StepFinal } from "../components/application/stepFinal/StepFinal";

export const Application = () => {
  const { productId, formPrefill, companyProductID } = useParams();

  const auth = useAuth();

  const [, /*isLoading*/ setIsLoading] = useState(false); //para implementar luego
  const [, /*error*/ setError] = useState(""); //para implementar luego
  const companyId = auth?.authState.user?.company;
  const token = "Bearer " + auth?.authState.token;

  const [step, setStep] = useState(1);
  const [isPrefill, setIsPrefill] = useState(false);
  const [productData, setproductData] = useState<any[]>([]);
  const [farmsDataFormatted, setfarmsDataFormatted] = useState([""]);
  const [farmsDataRaw, setfarmsDataRaw] = useState([]);
  const [subcompanyDataRaw, setsubcompanyDataRaw] = useState([]);
  const [subcompanyFormatted, setsubcompanyFormatted] = useState([""]);
  const [regulationRolesFormatted, setregulationRolesFormatted] = useState([
    "",
  ]);
  const [prefillFormsData, setPrefillFormsData] = useState<any[]>([]);

  const [sectorOptionsFormatted, setsectorOptionsFormatted] = useState([""]);
  const [farmIDselected, setfarmIDselected] = useState(0);

  //TODO: se propone usar signals para solucionar este problema de los arreglos https://www.youtube.com/watch?v=SO8lBVWF2Y8

  const isMounted = useRef(false);

  useEffect(() => {
    // Initial render has been completed
    // make the flag true
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (formPrefill === "1") {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let bodyObject = {
    //el bojeto que tiene todo el body de todos los pasos para la request
    "1_1350a2v": "",
    "1_1350a2vi": "",
    companyProductId: "",
    companyId: "",
    subCompanyId: "",
    farmId: "",
    rol1: "",
    dispatch1: "",
    "1_1315a5": "",
    "1_1320a": "",
    "1_1325a1i": "",
    "1_1325a1iii": "",
    "1_1325a1iv": "",
    "1_1325a1vii": "",
    "1_1325a1viii": "",
    "1_1325b1i": "",
    "1_1325b1iii": "",
    "1_1325b1iv": "",
    "1_1325b1v": "",
    "1_1325b1vi": "",
    "1_1325b1vii": "",
    "1_1330a2": "",
    "1_1330a3": "",
    "1_1330a4": "",
    "1_1330a8": "",
    "1_1330a9": "",
    "1_1330a10": "",
    "1_1330a11": "",
    "1_1330a12": "",
    "1_1330a13": "",
    "1_1330a14": "",
    "1_1330a15": "",
    "1_1330a16": "",
    "1_1330b1i": "",
    "1_1330b1ii": "",
    "1_1330b2i": "",
    "1_1330b2ii": "",
    "1_1330b3i": "",
    "1_1330b3ii": "",
    "1_1330b4i": "",
    "1_1330b4ii": "",
    "1_1330b4iii": "",
    "1_1330b6": "",
    "1_1330b7": "",
    "1_1335c": "",
    "1_1335di": "",
    "1_1335dii": "",
    "1_1335e": "",
    "1_1335f": "",
    "1_1335g": "",
    "1_1340a2": "",
    "1_1340a4": "",
    "1_1340a5": "",
    "1_1340a6": "",
    "1_1340a7": "",
    "1_1340a8": "",
    "1_1345a2": "",
    "1_1345a5": "",
    "1_1345a6": "",
    "1_1345a7": "",
    "1_1345a8": "",
    "1_1350a1iii": "",
    "1_1350a2i": "",
    "1_1350a2ii": "",
    "1_1350a2iii": "",
    "1_1350a2iv": "",
  };

  const fetchCompanyProduct = async () => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/companyProducts/${companyProductID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error al obtener el producto");
      }
      let data = await response.json();
      setproductData(data);
      //Manejar los datos del producto, loggeando puedes checkear lo que trae
    } catch (error: unknown) {
      console.error(error);
    }
  }; /*[productId, token]*/

  const formClear = async () => {
    //una vez se guardo la data, se borra el valor de los inputs para que no vuelvan a aparecer al llenar otro producto

    //step1 clear
    step1InputsArray[0].input_value = "";
    step1InputsArray[1].input_value = "";
    step1InputsArray[2].input_value = "";
    step1InputsArray[2].input_multiple_values = []; //multiple values must be cleaned in this attibute too
    step1InputsArray[3].input_value = "";
    step1InputsArray[4].input_value = "";

    //step2clear
    step2InputsArray[0].input_value = "";
    step2InputsArray[1].input_value = "";
    step2InputsArray[2].input_value = "";
    step2InputsArray[2].input_multiple_values = []; //multiple values must be cleaned in this attibute too
    step2InputsArray[3].input_value = "";
    step2InputsArray[4].input_value = "";

    //step3clear
    step3InputsArray[0].input_value = "";
    step3InputsArray[1].input_value = "";
    step3InputsArray[2].input_value = "";
    step3InputsArray[2].input_multiple_values = []; //multiple values must be cleaned in this attibute too
    step3InputsArray[3].input_value = "";
    step3InputsArray[4].input_value = "";
    step3InputsArray[4].input_multiple_values = []; //multiple values must be cleaned in this attibute too
    //step3InputsArray[5].input_value = "";

    //step4clear
    step4InputsArray[0].input_value = "";
    step4InputsArray[1].input_value = "";
    step4InputsArray[2].input_value = "";
    step4InputsArray[2].input_multiple_values = [];
    step4InputsArray[3].input_value = "";
    step4InputsArray[4].input_value = "";
    step4InputsArray[4].input_multiple_values = []; //multiple values must be cleaned in this attibute too
    step4InputsArray[5].input_value = "";
    step4InputsArray[6].input_value = "";
    step4InputsArray[7].input_value = "";
    step4InputsArray[8].input_value = "";
    step4InputsArray[9].input_value = "";
    step4InputsArray[9].input_multiple_values = []; //multiple values must be cleaned in this attibute too
    step4InputsArray[10].input_value = "";
    step4InputsArray[11].input_value = "";

    //step4.1clear
    step4_1InputsArray[0].input_value = "";
    step4_1InputsArray[1].input_value = "";
    step4_1InputsArray[2].input_value = "";
    step4_1InputsArray[3].input_value = "";
    step4_1InputsArray[4].input_value = "";
    step4_1InputsArray[5].input_value = "";
    step4_1InputsArray[6].input_value = "";
    step4_1InputsArray[7].input_value = "";
    step4_1InputsArray[8].input_value = "";
    step4_1InputsArray[9].input_value = "";
    step4_1InputsArray[10].input_value = "";

    //step5 clear
    step5InputsArray[0].input_value = "";
    step5InputsArray[1].input_value = "";
    step5InputsArray[2].input_value = "";
    step5InputsArray[3].input_value = "";
    step5InputsArray[4].input_value = "";
    step5InputsArray[5].input_value = "";

    //step6 clear
    step6InputsArray[0].input_value = "";
    step6InputsArray[1].input_value = "";
    step6InputsArray[2].input_value = "";
    step6InputsArray[3].input_value = "";
    step6InputsArray[4].input_value = "";
    step6InputsArray[5].input_value = "";

    //step7 clear
    step7InputsArray[0].input_value = "";
    step7InputsArray[1].input_value = "";
    step7InputsArray[2].input_value = "";
    step7InputsArray[3].input_value = "";
    step7InputsArray[4].input_value = "";

    //step8 clear
    step8InputsArray[0].input_value = "";
    step8InputsArray[1].input_value = "";
    step8InputsArray[2].input_value = "";
    step8InputsArray[3].input_value = "";
    step8InputsArray[4].input_value = "";
    step8InputsArray[5].input_value = "";
    step8InputsArray[6].input_value = "";

    let formPrefillInt = parseInt(
      formPrefill === undefined ? "0" : formPrefill
    );

    if (parseInt(formPrefill === undefined ? "0" : formPrefill) > 0) {
      prefillArray(formPrefillInt);
      fetchCompanyProduct();
    } else {
      if (prefillFormsData.length !== 1) {
        setPrefillFormsData(["1"]);
      }
    }
  };

  const prefillArray = async (formPrefillInt: number) => {
    await fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/form-applications/${formPrefillInt}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPrefillFormsData(data);
        setIsPrefill(true);
        //step1 prefill
        step1InputsArray[0].input_value = data?.["1_1315a5"];
        step1InputsArray[1].input_value = data?.subCompany?.subCompanyRut;
        step1InputsArray[2].input_value = data?.rol1;
        step1InputsArray[2].input_multiple_values = (data?.rol1).split(", "); //multiple values must be cleaned in this attibute too
        step1InputsArray[3].input_value = data?.["1_1320a"];
        step1InputsArray[4].input_value = data?.dispatch1;

        //prefillstep2
        step2InputsArray[0].input_value = data?.["1_1325a1i"];
        step2InputsArray[1].input_value = data?.["1_1325a1iii"];
        step2InputsArray[2].input_value = data?.["1_1325a1iv"];
        step2InputsArray[2].input_multiple_values = [
          data?.["1_1325a1iv"].split(", "),
        ]; //multiple values must be cleaned in this attibute too
        step2InputsArray[3].input_value = data?.["1_1325a1vii"];
        step2InputsArray[4].input_value = data?.["1_1325a1viii"];

        //step3prefill
        step3InputsArray[0].input_value = data?.["1_1325b1i"];
        step3InputsArray[1].input_value = data?.["1_1325b1iii"];
        step3InputsArray[2].input_value = data?.["1_1325b1iv"];
        step3InputsArray[2].input_multiple_values = [
          data?.["1_1325b1iv"].split(", "),
        ]; //multiple values must be cleaned in this attibute too
        step3InputsArray[3].input_value = data?.["1_1325b1v"];
        step3InputsArray[4].input_value = data?.["1_1325b1vi"];
        step3InputsArray[4].input_multiple_values = [
          data?.["1_1325b1vi"].split(", "),
        ]; //multiple values must be cleaned in this attibute too
        step3InputsArray[5].input_value = data?.["1_1325b1vii"];

        //step4prefill
        step4InputsArray[0].input_value = data?.["1_1330a2"];
        step4InputsArray[1].input_value = data?.["1_1330a3"];
        step4InputsArray[2].input_value = data?.["1_1330a4"];
        step4InputsArray[2].input_multiple_values = [
          data?.["1_1330a4"].split(", "),
        ];
        step4InputsArray[3].input_value = data?.["1_1330a8"];
        step4InputsArray[4].input_value = data?.["1_1330a9"];
        step4InputsArray[4].input_multiple_values = [
          data?.["1_1330a9"].split(", "),
        ]; //multiple values must be cleaned in this attibute too
        step4InputsArray[5].input_value = data?.["1_1330a10"];
        step4InputsArray[6].input_value = data?.["1_1330a11"];
        step4InputsArray[7].input_value = data?.["1_1330a12"];
        step4InputsArray[8].input_value = data?.["1_1330a13"];
        step4InputsArray[9].input_value = data?.["1_1330a14"];
        step4InputsArray[9].input_multiple_values = [
          data?.["1_1330a14"].split(", "),
        ]; //multiple values must be cleaned in this attibute too
        step4InputsArray[10].input_value = data?.["1_1330a15"];
        step4InputsArray[11].input_value = data?.["1_1330a16"];

        //step4.1prefill
        // step4_1InputsArray[0].input_value = data?.["1_1330b1i"];
        // step4_1InputsArray[1].input_value = data?.["1_1330b1ii"];
        // step4_1InputsArray[2].input_value = data?.["1_1330b2i"];
        // step4_1InputsArray[3].input_value = data?.["1_1330b2ii"];
        // step4_1InputsArray[4].input_value = data?.["1_1330b3i"];
        // step4_1InputsArray[5].input_value = data?.["1_1330b3ii"];
        // step4_1InputsArray[6].input_value = data?.["1_1330b4i"];
        // step4_1InputsArray[7].input_value = data?.["1_1330b4ii"];
        // step4_1InputsArray[8].input_value = data?.["1_1330b4iii"];
        // step4_1InputsArray[9].input_value = data?.["1_1330b6"];
        // step4_1InputsArray[10].input_value = data?.["1_1330b7"];

        //step5 prefill
        step5InputsArray[0].input_value = data?.["1_1335c"];
        step5InputsArray[1].input_value = data?.["1_1335di"];
        step5InputsArray[2].input_value = data?.["1_1335dii"];
        step5InputsArray[3].input_value = data?.["1_1335e"];
        step5InputsArray[4].input_value = data?.["1_1335f"];
        step5InputsArray[5].input_value = data?.["1_1335g"];

        //step6prefill
        step6InputsArray[0].input_value = data?.["1_1340a2"];
        step6InputsArray[1].input_value = data?.["1_1340a4"];
        step6InputsArray[2].input_value = data?.["1_1340a5"];
        step6InputsArray[3].input_value = data?.["1_1340a6"];
        step6InputsArray[4].input_value = data?.["1_1340a7"];
        step6InputsArray[5].input_value = data?.["1_1340a8"];

        //step7prefill
        step7InputsArray[0].input_value = data?.["1_1345a2"];
        step7InputsArray[1].input_value = data?.["1_1345a5"];
        step7InputsArray[2].input_value = data?.["1_1345a6"];
        step7InputsArray[3].input_value = data?.["1_1345a7"];
        step7InputsArray[4].input_value = data?.["1_1345a8"];

        //step8prefill
        step8InputsArray[0].input_value = data?.["1_1350a1iii"];
        step8InputsArray[1].input_value = data?.["1_1350a2i"];
        step8InputsArray[2].input_value = data?.["1_1350a2ii"];
        step8InputsArray[3].input_value = data?.["1_1350a2iii"];
        step8InputsArray[4].input_value = data?.["1_1350a2iv"];
        step8InputsArray[5].input_value = data?.["1_1350a2v"];
        step8InputsArray[6].input_value = data?.["1_1350a2vi"];
      })
      .catch((error) => {
        console.error("Error fetching farms:", error);
      });
  };

  if (!isMounted.current) {
    // before rendering, cleans form from previous data
    formClear();
  }

  const setUploadData = () => {
    //prepara la data para enviarla una vez el form esta llenado y el cliente cliquea "guardar"

    //productData
    bodyObject["companyProductId"] = productId === undefined ? "" : productId;
    bodyObject["companyId"] = companyId === undefined ? "" : companyId;

    //farmData
    bodyObject["farmId"] =
      farmIDselected === undefined ? "" : farmIDselected.toString();
    //step1
    bodyObject["1_1315a5"] = step1InputsArray[0].input_value;
    bodyObject["subCompanyId"] = step1InputsArray[1].input_value;
    bodyObject["rol1"] = step1InputsArray[2].input_value;
    bodyObject["1_1320a"] = step1InputsArray[3].input_value;
    bodyObject["dispatch1"] = step1InputsArray[4].input_value;

    //step2
    bodyObject["1_1325a1i"] = step2InputsArray[0].input_value;
    bodyObject["1_1325a1iii"] = step2InputsArray[1].input_value;
    bodyObject["1_1325a1iv"] = step2InputsArray[2].input_value;
    bodyObject["1_1325a1vii"] = step2InputsArray[3].input_value;
    bodyObject["1_1325a1viii"] = step2InputsArray[4].input_value;

    //step3
    bodyObject["1_1325b1i"] = step3InputsArray[0].input_value;
    bodyObject["1_1325b1iii"] = step3InputsArray[1].input_value;
    bodyObject["1_1325b1iv"] = step3InputsArray[2].input_value;
    bodyObject["1_1325b1v"] = step3InputsArray[3].input_value;
    bodyObject["1_1325b1vi"] = step3InputsArray[4].input_value;
    bodyObject["1_1325b1vii"] = step3InputsArray[5].input_value;

    //step4
    bodyObject["1_1330a2"] = step4InputsArray[0].input_value;
    bodyObject["1_1330a3"] = step4InputsArray[1].input_value;
    bodyObject["1_1330a4"] = step4InputsArray[2].input_value;
    bodyObject["1_1330a8"] = step4InputsArray[3].input_value;
    bodyObject["1_1330a9"] = step4InputsArray[4].input_value;
    bodyObject["1_1330a10"] = step4InputsArray[5].input_value;
    bodyObject["1_1330a11"] = step4InputsArray[6].input_value;
    bodyObject["1_1330a12"] = step4InputsArray[7].input_value;
    bodyObject["1_1330a13"] = step4InputsArray[8].input_value;
    bodyObject["1_1330a14"] = step4InputsArray[9].input_value;
    bodyObject["1_1330a15"] = step4InputsArray[10].input_value;
    bodyObject["1_1330a16"] = step4InputsArray[11].input_value;

    //step4.1
    bodyObject["1_1330b1i"] = step4_1InputsArray[0].input_value;
    bodyObject["1_1330b1ii"] = step4_1InputsArray[1].input_value;
    bodyObject["1_1330b2i"] = step4_1InputsArray[2].input_value;
    bodyObject["1_1330b2ii"] = step4_1InputsArray[3].input_value;
    bodyObject["1_1330b3i"] = step4_1InputsArray[4].input_value;
    bodyObject["1_1330b3ii"] = step4_1InputsArray[5].input_value;
    bodyObject["1_1330b4i"] = step4_1InputsArray[6].input_value;
    bodyObject["1_1330b4ii"] = step4_1InputsArray[7].input_value;
    bodyObject["1_1330b4iii"] = step4_1InputsArray[8].input_value;
    bodyObject["1_1330b6"] = step4_1InputsArray[9].input_value;
    bodyObject["1_1330b7"] = step4_1InputsArray[10].input_value;

    //step5
    bodyObject["1_1335c"] = step5InputsArray[0].input_value;
    bodyObject["1_1335di"] = step5InputsArray[1].input_value;
    bodyObject["1_1335dii"] = step5InputsArray[2].input_value;
    bodyObject["1_1335e"] = step5InputsArray[3].input_value;
    bodyObject["1_1335f"] = step5InputsArray[4].input_value;
    bodyObject["1_1335g"] = step5InputsArray[5].input_value;

    //step6
    bodyObject["1_1340a2"] = step6InputsArray[0].input_value;
    bodyObject["1_1340a4"] = step6InputsArray[1].input_value;
    bodyObject["1_1340a5"] = step6InputsArray[2].input_value;
    bodyObject["1_1340a6"] = step6InputsArray[3].input_value;
    bodyObject["1_1340a7"] = step6InputsArray[4].input_value;
    bodyObject["1_1340a8"] = step6InputsArray[5].input_value;

    //step7
    bodyObject["1_1345a2"] = step7InputsArray[0].input_value;
    bodyObject["1_1345a5"] = step7InputsArray[1].input_value;
    bodyObject["1_1345a6"] = step7InputsArray[2].input_value;
    bodyObject["1_1345a7"] = step7InputsArray[3].input_value;
    bodyObject["1_1345a8"] = step7InputsArray[4].input_value;

    //step8
    bodyObject["1_1350a1iii"] = step8InputsArray[0].input_value;
    bodyObject["1_1350a2i"] = step8InputsArray[1].input_value;
    bodyObject["1_1350a2ii"] = step8InputsArray[2].input_value;
    bodyObject["1_1350a2iii"] = step8InputsArray[3].input_value;
    bodyObject["1_1350a2iv"] = step8InputsArray[4].input_value;
    bodyObject["1_1350a2v"] = step8InputsArray[5].input_value;
    bodyObject["1_1350a2vi"] = step8InputsArray[6].input_value;
  };

  const uploadData = async () => {
    //envia la data de todos lis inputs
    setUploadData();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://backend-iawdfmhnsa-uc.a.run.app/form-applications/",
        {
          method: "POST",
          headers: {
            //"Content-Type": "multipart/form-data",
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(bodyObject),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      /*const data = */ await response.json(); //para mostrar mensajes de error luego
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const setfarmsData = (data: any) => {
    let result: string[] = [];
    data.forEach((index: any) => {
      result.push(index?.name);
    });
    setfarmsDataFormatted(result);
  };

 

  const fetchFarms = async () => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/farm/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error al obtener el producto");
      }
      let data = await response.json();
      setfarmsDataRaw(data);
      setfarmsData(data);
      //setOptions(data);
      //Manejar los farms
    } catch (error: unknown) {
      console.error(error);
    }
  }; /*[productId, token]*/

  const setSocianRoles = (data: any) => {
    let result: string[] = [];
    data.forEach((index: any) => {
      result.push(index?.subCompanyName);
    });
    setsubcompanyFormatted(result);
  };

  const fetchSubcompany = async () => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/companies/${companyId}/subCompanies`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error al obtener el producto");
      }
      let data = await response.json();
      setSocianRoles(data);
      setsubcompanyDataRaw(data);
      //setOptions(data);
      //Manejar los farms
    } catch (error: unknown) {
      console.error(error);
    }
  }; /*[productId, token]*/

  const setRolsForInput = (data: any) => {
    setregulationRolesFormatted(data.roles);
  };

  const fetchProduct = async () => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/companyProducts/${productId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error al obtener el producto");
      }
      let data = await response.json();
      setproductData(data);
      setRolsForInput(data);
      //Manejar los datos del producto, loggeando puedes checkear lo que trae
    } catch (error: unknown) {
      console.error(error);
    }
  }; /*[productId, token]*/

  useEffect(() => {
    //traer la data delproducto cuyo id llego por url parameter
    // fetchCompanyProduct();
    fetchProduct();
    fetchFarms();
    fetchSubcompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFarmIDSelectedStep1 = () => {
    farmsDataRaw.forEach((index: any) => {
      if (step1InputsArray[0].input_value === index.name) {
        setfarmIDselected(index.id);
        fetchSectors(index.id);
      }
    });
  };

  const setSectorsForOptions = (sectorData: any) => {
    let result: string[] = [];
    sectorData.forEach((index: any) => {
      result.push(index?.sectorName);
    });
    setsectorOptionsFormatted(result);
  };

  const fetchSectors = async (farmId: number) => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/farm/sector/farm/${farmId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error al obtener el producto");
      }
      let data = await response.json();

      //Manejar los datos del producto, loggeando puedes checkear lo que trae
      //setfarmIDselected(data);

      setSectorsForOptions(data);
    } catch (error: unknown) {
      console.error(error);
    }
  }; /*[productId, token]*/

  const findInStringArray = (strArray: string[], value: string) => {
    let result = false;
    strArray.forEach((str: string) => {
      if (str === value) result = true;
    });
    return result;
  };

  const isTheRoleValid = (productData: any, Role: string) => {
    if (findInStringArray(productData?.roles, Role)) {
      return true;
    }
    return false;
  };

  const findAndSetSubcompanyID = () => {
    subcompanyDataRaw.forEach((index: any) => {
      if (step1InputsArray[1].input_value === index?.subCompanyName) {
        step1InputsArray[1].input_value = index?.id;
      }
    });
    //setsubcompanyFormatted(result);
  };

  const preFiller = (
    stepToLook: number,
    keyToFind: number,
    valueToSet: string
  ) => {
    //si un input de un paso llena un input de un paso superior, debe utilizar esta funcion aca
    //let auxiliaryArray;
    let isTheChangeValid; // si hay un preset puesto pero no cumpole ese rol, se validara para no prellenarlo
    if (stepToLook === 1) {
      //step1 cant prefill
    }
    if (stepToLook === 2) {
      //auxiliaryArray = step2InputsArray;
      isTheChangeValid = isTheRoleValid(productData, "Cosecha");
      if (isTheChangeValid) {
        step2InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 3) {
      // auxiliaryArray = step3InputsArray;
      isTheChangeValid = isTheRoleValid(productData, "Enfría");
      if (isTheChangeValid) {
        step3InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 4) {
      //auxiliaryArray = step4InputsArray;
      isTheChangeValid =
        isTheRoleValid(productData, "Empaca") &&
        !isTheRoleValid(productData, "Brote");
      if (isTheChangeValid) {
        step4InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 4.1) {
      //auxiliaryArray = step4_1InputsArray;
      isTheChangeValid =
        isTheRoleValid(productData, "Empaca") &&
        isTheRoleValid(productData, "Brote");
      if (isTheChangeValid) {
        step4_1InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 5) {
      //auxiliaryArray = step5InputsArray;
      isTheChangeValid = isTheRoleValid(
        productData,
        "Recibe de Buque en tierra"
      );
      if (isTheChangeValid) {
        step5InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 6) {
      //auxiliaryArray = step6InputsArray;
      isTheChangeValid = isTheRoleValid(productData, "Despacha");
      if (isTheChangeValid) {
        step6InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 7) {
      //auxiliaryArray = step7InputsArray;
      isTheChangeValid = isTheRoleValid(productData, "Recibe");
      if (isTheChangeValid) {
        step7InputsArray[keyToFind].input_value = valueToSet;
      }
    }
    if (stepToLook === 8) {
      //auxiliaryArray = step8InputsArray;
      isTheChangeValid = isTheRoleValid(productData, "Transformador");
      if (isTheChangeValid) {
        step8InputsArray[keyToFind].input_value = valueToSet;
      }
    }
  };

  return (
    //TODO: se debe hacer un componente mas factorizado en el futuro para que no sea tan redundante los archivos
    <>
      {<HomeNavbar />}
      {step === 1 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep1
              productData={productData}
              setStep={setStep}
              step1InputsArray={step1InputsArray}
              farmOptions={farmsDataFormatted}
              socialRoleOptions={subcompanyFormatted}
              rolesOptions={regulationRolesFormatted}
              setFarmIDSelected={setFarmIDSelectedStep1}
            />
          )}
        </>
      )}
      {step === 2 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep2
              productData={productData}
              setStep={setStep}
              step2InputsArray={step2InputsArray}
              preFiller={preFiller}
              sectorOptions={sectorOptionsFormatted}
            />
          )}
        </>
      )}
      {step === 3 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep3
              productData={productData}
              setStep={setStep}
              step3InputsArray={step3InputsArray}
              sectorOptions={sectorOptionsFormatted}
            />
          )}
        </>
      )}
      {step === 4 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep4
              productData={productData}
              setStep={setStep}
              step4InputsArray={step4InputsArray}
              sectorOptions={sectorOptionsFormatted}
            />
          )}
        </>
      )}
      {step === 4.1 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep4_1
              productData={productData}
              setStep={setStep}
              step4_1InputsArray={step4_1InputsArray}
            />
          )}
        </>
      )}
      {step === 5 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep5
              productData={productData}
              setStep={setStep}
              step5InputsArray={step5InputsArray}
            />
          )}
        </>
      )}
      {step === 6 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep6
              productData={productData}
              setStep={setStep}
              step6InputsArray={step6InputsArray}
            />
          )}
        </>
      )}
      {step === 7 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep7
              productData={productData}
              setStep={setStep}
              step7InputsArray={step7InputsArray}
            />
          )}
        </>
      )}
      {step === 8 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <ApplicationStep8
              productData={productData}
              setStep={setStep}
              step8InputsArray={step8InputsArray}
            />
          )}
        </>
      )}
      {step === 99 && (
        <>
          {" "}
          {prefillFormsData?.length === 0 ? (
            <>VACIO</>
          ) : (
            <StepFinal
              productData={productData}
              setStep={setStep}
              uploadData={uploadData}
              formClear={formClear}
              findAndSetSubcompanyID={findAndSetSubcompanyID}
              isPrefill={isPrefill}
            />
          )}
        </>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useAuth } from '../../context/authContext';

interface Product {
  id: number;
  name: string;
  variety: string;
  imageUrl: string;
}

interface ProductDropdownProps {
  onProductSelect: (product: Product | null) => void;
}

const ProductDropdown: React.FC<ProductDropdownProps> = ({ onProductSelect }) => {
  const auth = useAuth();
  const [options, setOptions] = useState<Product[]>([]);
  const [inputValue, setInputValue] = useState('');
  const token = auth?.authState.token;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          'https://backend-iawdfmhnsa-uc.a.run.app/regulatedProducts',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + token,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const productos: Product[] = await response.json();
        setOptions(productos);
      } catch (error) {
        console.error('Error getting products:', error);
      }
    };

    fetchProducts();
  }, [token]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue: Product | null) => {
        onProductSelect(newValue);
      }}
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          label='Buscar productos'
          variant='outlined'
          margin='normal'
        />
      )}
    />
  );
};

export default ProductDropdown;
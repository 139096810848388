import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

export const Register = () => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        sx={{
          position: 'absolute',
          top: '5%',
          left: '5%',
          bgcolor: '#1b4962',
          color: 'white',
          borderRadius: '10px',
        }}
        onClick={() => navigate('/')}
      >
        Home
      </Button>
      <Box
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <TextField required label='Nombre de Usuario' variant='outlined' />
        <TextField
          required
          label='Contraseña'
          type='password'
          variant='outlined'
        />
        <Button variant='contained' color='primary'>
          Iniciar Sesión
        </Button>
      </Box>
    </>
  );
};

let step3InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
}[] = [
  {
    key: "1_1325b1i",
    input_id: 0,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.1 Descripción del destinatario  del alimento cosechado",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la dirección y nombre del destinatario",
  },
  {
    key: "1_1325b1iii",
    input_id: 1,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.3 Cantidad y unidad de medida del alimento (p.ej., 75 contenedores, 200 Kilos)",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1325b1iv",
    input_id: 2,
    input_type: 3, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.4 Lugar donde se enfrió el alimento",
    options: ["lugar1", "lugar2", "lugar3"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el lugar según mapa",
  },
  {
    key: "1_1325b1v",
    input_id: 3,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.5 Fecha de enfriamiento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
  },
  {
    key: "1_1325b1vi",
    input_id: 4,
    input_type: 3, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.6 Lugar de la Finca donde se cosechó",
    options: ["lugar1", "lugar2", "lugar3"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Selecciona el lugar según mapa",
  },
  {
    key: "1_1325b1vii",
    input_id: 5,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.8 Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
  },
];

export default step3InputsArray;

import { HeroSectionTop } from '../components/HeroSection';
import { HeroSectionBody } from '../components/HeroSectionBody';
import { HeroSectionBodyBlanck } from '../components/HeroSectionBodyBlanck';
import { HeroSectionBodyContact } from '../components/HeroSectionBodyContact';
import { HeroSectionBodyIcons } from '../components/HeroSectionBodyIcons';
import { HeroSectionBodyIconsUs } from '../components/HeroSectionBodyIconsUs';
import { NavBar } from '../components/Navbar';

export const Home: React.FC = () => {
  return (
    <>
      <NavBar />
      <HeroSectionTop url='url(img/elaine-casap-qgHGDbbSNm8-unsplash1.png)' />
      <HeroSectionBodyBlanck />
      <HeroSectionBody url='url(img/tim-mossholder-xDwEa2kaeJA-unsplash1.png)' />
      <HeroSectionBodyIcons />
      <HeroSectionBodyContact />
      <HeroSectionBodyIconsUs />
    </>
  );
};

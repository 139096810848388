import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Box, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteFarmDialog from "./DeleteFarmDialog";
import SectorForm from "./SectorForm";

interface FarmData {
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  imageUrl: string;
  sectors: Sector[];
}

interface Sector {
  id: number;
  sectorName: string;
}

interface FarmFormProps {
  toggle: () => void;
  onFormSubmit: (formData: FormData, farmId?: string) => void;
  editingFarm: FarmData | null;
  handleDelete: () => void;
}

export const FarmForm = ({
  onFormSubmit,
  toggle,
  editingFarm,
  handleDelete,
}: FarmFormProps) => {
  const [farmName, setFarmName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [touched, setTouched] = useState({
    farmName: false,
    location: false,
    latitude: false,
    longitude: false,
  });
  const [file, setFile] = useState<File | null>(null);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  // Set form data if editing a farm
  useEffect(() => {
    if (editingFarm) {
      setFarmName(editingFarm.name);
      setLocation(editingFarm.address);
      setLatitude(editingFarm.latitude);
      setLongitude(editingFarm.longitude);
      setSectors(editingFarm.sectors);
    } else {
      handleCleanForm();
    }
  }, [editingFarm]);

  //Send form data to backend
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", farmName);
    formData.append("address", location);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    if (file) formData.append("image", file);
    if (sectors && sectors.length > 0) {
      formData.append("sectors", JSON.stringify(sectors));
    }

    onFormSubmit(formData, editingFarm ? editingFarm.id : undefined);
    setIsSubmitting(false);
    handleCleanForm();
  };

  //Clear form data
  const handleCleanForm = () => {
    setFarmName("");
    setLocation("");
    setLatitude("");
    setLongitude("");
    setFile(null);
  };

  //Dialog handling
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //Input handling
  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const handleCoordinateChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const { value } = event.target;
    // Allow only numbers, dots and negative sign
    const regex = /^-?\d*\.?\d*$/;

    if (regex.test(value)) {
      setter(value);
    }
  };

  //TODO: Move validations to a helper file
  const isEmpty = (value: string) => !value.trim();

  const isValidCoordinate = (
    value: string,
    type: "latitude" | "longitude"
  ): boolean => {
    const numberValue = parseFloat(value);
    if (type === "latitude" && (numberValue < -90 || numberValue > 90))
      return false;
    if (type === "longitude" && (numberValue < -180 || numberValue > 180))
      return false;
    return true;
  };

  //Validate form
  useEffect(() => {
    const isFarmNameValid = !isEmpty(farmName);
    const isLocationValid = !isEmpty(location);
    const isLatitudeValid = isValidCoordinate(latitude, "latitude");
    const isLongitudeValid = isValidCoordinate(longitude, "longitude");
    const areSectorsValid = sectors.every(
      (sector) => !isEmpty(sector.sectorName)
    );
    const isFileValid =
      file !== null || (editingFarm !== null && editingFarm.imageUrl !== "");

    setIsFormValid(
      isFarmNameValid &&
        isLocationValid &&
        isLatitudeValid &&
        isLongitudeValid &&
        areSectorsValid &&
        isFileValid
    );
  }, [farmName, location, latitude, longitude, sectors, file]);

  //Helper text for coordinate fields
  const coordinateHelperText = (
    value: string,
    type: "latitude" | "longitude"
  ): string => {
    if (isEmpty(value)) return "Campo requerido";

    if (type === "latitude" && !isValidCoordinate(value, "latitude"))
      return "Latitud inválida";
    if (type === "longitude" && !isValidCoordinate(value, "longitude"))
      return "Longitud inválida";

    return "";
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          sx={{
            fontSize: "20px",
            color: "#093BA0",
            fontWeight: "bold",
            mb: 3,
          }}
        >
          {editingFarm
            ? "Edita la información de tu finca"
            : "Ingresa la información de tus fincas"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            bgcolor: "#F4F4F4",
            pt: 3,
            pl: 6,
            pr: 8,
            pb: 5,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Nombre finca"
                value={farmName}
                onChange={(e) => setFarmName(e.target.value)}
                onBlur={() => handleBlur("farmName")}
                fullWidth
                margin="normal"
                variant="outlined"
                error={touched.farmName && isEmpty(farmName)}
                helperText={
                  touched.farmName && isEmpty(farmName) ? "Campo requerido" : ""
                }
                sx={{ backgroundColor: "white" }}
              />
              <TextField
                label="Dirección"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                onBlur={() => handleBlur("location")}
                fullWidth
                margin="normal"
                variant="outlined"
                error={touched.location && isEmpty(location)}
                helperText={
                  touched.location && isEmpty(location) ? "Campo requerido" : ""
                }
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Latitud"
                value={latitude}
                onChange={(e) => handleCoordinateChange(e, setLatitude)}
                onBlur={() => handleBlur("latitude")}
                fullWidth
                margin="normal"
                variant="outlined"
                error={
                  touched.latitude && !isValidCoordinate(latitude, "latitude")
                }
                helperText={
                  touched.latitude && coordinateHelperText(latitude, "latitude")
                }
                sx={{ backgroundColor: "white" }}
              />
              <TextField
                label="Longitud"
                value={longitude}
                onChange={(e) => handleCoordinateChange(e, setLongitude)}
                onBlur={() => handleBlur("longitude")}
                fullWidth
                margin="normal"
                variant="outlined"
                error={
                  touched.longitude &&
                  !isValidCoordinate(longitude, "longitude")
                }
                helperText={
                  touched.longitude &&
                  coordinateHelperText(longitude, "longitude")
                }
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <SectorForm sectors={sectors} setSectors={setSectors} />
            </Grid>
            <Grid container sx={{ ml: 1, mt: 2 }}>
              <Grid item xs={12}>
                <label htmlFor="raised-button-file">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files && e.target.files.length > 0) {
                        setFile(e.target.files[0]);
                      }
                    }}
                    disabled={file !== null}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    disabled={file !== null}
                    sx={{
                      borderRadius: "30px",
                      backgroundColor: "#093BA0",
                      color: "#FFFFFF",
                      paddingX: "30px",
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        backgroundColor: "#FFFFFF",
                        color: "#093BA0",
                      },
                    }}
                  >
                    {"Subir imagen de la finca"}
                  </Button>
                  {file !== null ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setFile(null)}
                    >
                      <ClearIcon />
                    </Button>
                  ) : (
                    <></>
                  )}
                </label>
              </Grid>
              <Grid container sx={{ mt: 4 }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  startIcon={<CancelOutlined />}
                  disabled={isSubmitting}
                  sx={{
                    marginRight: "40px",
                    borderRadius: "30px",
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: "#000000",
                    paddingX: "30px",
                    textTransform: "none",
                    fontSize: "1.1rem",
                    "&:hover": {
                      backgroundColor: "rgb(255, 255, 255, 0.7)",
                      borderColor: "transparent",
                      color: "#000000",
                    },
                  }}
                  onClick={editingFarm ? handleOpenDialog : toggle}
                >
                  {editingFarm ? "Eliminar" : "Cancelar"}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                  disabled={!isFormValid || isSubmitting}
                  sx={{
                    borderRadius: "30px",
                    backgroundColor: "#093BA0",
                    color: "#FFFFFF",
                    paddingX: "30px",
                    textTransform: "none",
                    fontSize: "1.1rem",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#093BA0",
                    },
                  }}
                >
                  {editingFarm ? "Guardar cambios" : "Agregar"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <DeleteFarmDialog
            isOpen={openDialog}
            onClose={handleCloseDialog}
            onDelete={handleDelete}
          />
        </Box>
      </Grid>
    </>
  );
};

/* eslint-disable array-callback-return */
import * as React from "react";
import styles from "./traceabilityPlan.style.module.css";
import { useEffect, useState } from "react";
import { StandardButton } from "../reusables/StandardButton/StandardButton";
import { Autocomplete, TextField } from "@mui/material";
import { HomeNavbar } from "../HomeNavbar";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { Traceability } from "../../pages/Traceability";

///TODO: hacer que el boton de guardar no se pueda pisar si no estan todos los inputs llenos
export const TraceabilityPlan = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const companyId = auth?.authState.user?.company;
  const token = "Bearer " + auth?.authState.token;
  const [valueRegistryDescription, setvalueRegistryDescription] = useState("");
  const [valueidentifierAliments, setvalueidentifierAliments] = useState("");
  const [valuecontactPoint, setvaluecontactPoint] = useState("");
  const [companyProductData, setCompanyProductData] = useState([]);
  const [regulatedProductID, setRegulatedProductID] = useState();
  const [isNewTraceabilityPlan, setisNewTraceabilityPlan] = useState(true);

  const [options, setoptions] = useState([""]);
  //const [isNewTraceabilityPlan, setsIsNewTraceabilityPlan] = useState(true);
  const [selectedProduct, setselectedProduct] = useState("");

  const uploadData = async (productID: string) => {
    try {
      const response = await fetch(
        "https://backend-iawdfmhnsa-uc.a.run.app/traceability/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            registryDescription: valueRegistryDescription,
            identifierAliments: valueidentifierAliments,
            contactPoint: valuecontactPoint,
            companyProductId: productID,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.json();
    } catch (error: unknown) {
      if (error instanceof Error) {
      }
    } finally {
    }
  };

  const uploadPatchData = async () => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/traceability/company/${companyId}/product/${regulatedProductID}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            registryDescription: valueRegistryDescription,
            identifierAliments: valueidentifierAliments,
            contactPoint: valuecontactPoint,

          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.json();
    } catch (error: unknown) {
      if (error instanceof Error) {
      }
    } finally {
    }
  };

  const getSelectedRegulatedProductID = (selectedProduct: any) => {
    
    companyProductData?.forEach((index: any) => {
        if(index?.regulatedProduct?.name === selectedProduct) {
            setRegulatedProductID(index?.regulatedProduct?.id);
            fetchProductTraceability(index?.regulatedProduct?.id) ;
        }
    });
    };
  

  const fetchProductTraceability = async (regulatedProductID: any) => {

      const response = await fetch(
        
        `https://backend-iawdfmhnsa-uc.a.run.app/traceability/company/${companyId}/product/${regulatedProductID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
        .then((res) => res.json())
        .then((data) =>{
          if (data?.length === 0){
            setvalueRegistryDescription("");
            setvalueidentifierAliments("");
            setvaluecontactPoint("");
            setisNewTraceabilityPlan(true);
          }else{
            setvalueRegistryDescription(data[0]?.registryDescription);
            setvalueidentifierAliments(data[0]?.identifierAliments);
            setvaluecontactPoint(data[0]?.contactPoint);
            setisNewTraceabilityPlan(false);
          }


        })
        .catch((error) => {
          console.error("Error fetching farms:", error);
        });
  };

  const getProductID = () => {
    let productIDresult = "";
    companyProductData.map((index: any) => {
      if (index?.regulatedProduct?.name === selectedProduct) {
        productIDresult = index?.id;
      }
    });
    return productIDresult;
  };

  const handleFormatOptionsData = (data: any) => {
    setCompanyProductData(data);
    let optionsToSet: string[] = [];
    data.forEach((index: any) => {
      optionsToSet.push(index?.regulatedProduct?.name);
    });
    setoptions(optionsToSet);
  };
  useEffect(() => {
    fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/companyProducts/company/${companyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {handleFormatOptionsData(data);})
      .catch((error) => {
        //TODO:Manejar el error
        console.error("Error fetching farms:", error);
      });
  }, [companyId, token]);

  return (
    <>
      <div className={styles.step1Container}>
        {<HomeNavbar />}
        <h2 className={styles.titleText}>Plan de trazabilidad</h2>
        <p className={styles.subtitleText}>Sección 1.1315</p>
        <div className={styles.stepInformationContainer}>
          <p className={styles.informationTitleText}>
            Completa la siguiente información requerida
          </p>
          <div className={styles.inputsContainer}>
            <div className={styles.selectorcontainer}>
              <div className={styles.singleInputContainer}>
                <div className={styles.textfieldContainer}>
                  <p className={styles.upperText}>Selecciona el producto</p>
                  <Autocomplete
                    value={selectedProduct}
                    onChange={(event: any, newValue: string | null) => {
                      // fetchProductTraceability();
                      getSelectedRegulatedProductID(newValue);
                      setselectedProduct(newValue === null ? "" : newValue);
                    }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label={"Nombre del producto"} />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className={styles.singleInputContainer}>
              <div className={styles.textfieldContainer}>
                <p className={styles.upperText}>
                  a.1 Una descripción de los procedimientos que utiliza para
                  mantener los registros que debe conservar según esta subparte,
                  incluido el formato y la ubicación de estos registros. (Debe
                  definir cómo y quién hace el registro.
                  <strong>
                    {" "}
                    El formato es digital y la ubicación es en la nube
                  </strong>
                  )
                </p>
                <TextField
                  id="outlined-basic"
                  label={"Escribe la descripción ...."}
                  sx={{
                    width: "100%",
                    size: "large",
                  }}
                  variant="outlined"
                  onChange={({ target: { value } }) => {
                    setvalueRegistryDescription(value);
                  }}
                  value={valueRegistryDescription}
                />
              </div>
            </div>
            <div className={styles.singleInputContainer}>
              <div className={styles.textfieldContainer}>
                <p className={styles.upperText}>
                  a.2 Una descripción de{" "}
                  <strong>
                    los procedimientos que utiliza para identificar los
                    alimentos
                  </strong>{" "}
                  en la Lista de Trazabilidad de Alimentos que fabrica, procesa,
                  empaca o conserva.
                </p>
                <TextField
                  id="outlined-basic"
                  label={"Escribe la descripción ...."}
                  sx={{
                    width: "100%",
                    size: "large",
                  }}
                  variant="outlined"
                  onChange={({ target: { value } }) => {
                    setvalueidentifierAliments(value);
                  }}
                  value={valueidentifierAliments}
                />
              </div>
            </div>
            <div className={styles.singleInputContainer}>
              <div className={styles.textfieldContainer}>
                <p className={styles.upperText}>
                  a.4 Un punto de contacto para preguntas relacionadas con su
                  plan y registros de trazabilidad (Nombre, correo y teléfono)
                </p>
                <TextField
                  id="outlined-basic"
                  label={"Escribe la descripción ...."}
                  sx={{
                    width: "100%",
                    size: "large",
                  }}
                  variant="outlined"
                  onChange={({ target: { value } }) => {
                    setvaluecontactPoint(value);
                  }}
                  value={valuecontactPoint}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <StandardButton
              executableFunction={async () => {
                isNewTraceabilityPlan? await uploadData(getProductID()): await uploadPatchData();
                await navigate("/menu");
              }}
              innerText="Guardar"
            />
          </div>
        </div>
      </div>
    </>
  );
};

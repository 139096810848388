import * as React from "react";
import styles from "./SecondaryButton.style.module.css";

interface SecondaryButtonProps {
  executableFunction: () => any;
  innerText: string;
}

export const SecondaryButton = ({
  executableFunction,
  innerText,
}: SecondaryButtonProps) => {
  return (
    <>
      <div
        className={styles.buttonContainer}
        onClick={() => executableFunction()}
      >
        <p className={styles.buttonText}>{innerText}</p>
      </div>
    </>
  );
};

let step4_1InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
}[] = [
  {
    key: "1_1330b1i",
    input_id: 0,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.1 Descripción del lugar del productor de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descripción",
  },
  {
    //**** */
    key: "1_1330b1ii",
    input_id: 1,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.1 Fecha de la cosecha de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione una fecha",
  },
  {
    key: "1_1330b2i",
    input_id: 2,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.2 Descripción del lugar del procesador de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descripción",
  },
  {
    /*** */
    key: "1_1330b2ii",
    input_id: 3,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.2 Fecha de procesamiento de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione una fecha",
  },
  {
    key: "1_1330b3i",
    input_id: 4,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.3 Descripción del lugar del empacadora de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descricpción",
  },
  {
    /*** */
    key: "1_1330b3ii",
    input_id: 5,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.3 Fecha de empaque de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
  },
  {
    key: "1_1330b4i",
    input_id: 6,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.4 Descripción del lugar del proveedor de semillas",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descripción",
  },
  {
    /** */
    key: "1_1330b4ii",
    input_id: 7,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.4 Código de lote de semillas asigando por el proveedor",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descricpción",
  },
  {
    /** */
    key: "1_1330b4iii",
    input_id: 8,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.4 Código de lote de semillas asigando por el germinador",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descripción",
  },
  {
    key: "1_1330b6",
    input_id: 9,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "b.6 Fecha de recepción de semillas por parte del germinador",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione una fecha",
  },
  {
    key: "1_1330b7",
    input_id: 10,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "b.7 Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
  },
];

export default step4_1InputsArray;

import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Paper, Typography, Box, Button } from "@mui/material";
import { FarmListItem } from "./FarmListItem";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

export const FarmList: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [farms, setFarms] = useState([]);
  const companyId = auth?.authState.user?.company;

  useEffect(() => {
    fetch(`https://backend-iawdfmhnsa-uc.a.run.app/farm/${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth?.authState.token,
      },
    })
      .then((res) => res.json())
      .then((data) => setFarms(data))
      .catch((error) => {
        //TODO:Manejar el error
        console.error("Error fetching farms:", error);
      });
  }, [companyId, auth?.authState.token]);

  return (
    <Paper
      sx={{
        maxWidth: 800,
        maxHeight: 600,
        mx: "auto",
        p: 2,
        backgroundColor: "#F4F4F4",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color="#093BA0">
          MIS FINCAS
        </Typography>
        <Button
          startIcon={<EditOutlined />}
          size="small"
          sx={{ color: "#093BA0", ml: 4 }}
          onClick={() => navigate("/farms")}
        >
          Editar
        </Button>
      </Box>
      <List sx={{ width: "100%" }}>
        {farms && farms.length > 0 ? (
          farms.map((farm: any) => (
            <FarmListItem key={farm.id} name={farm.name} />
          ))
        ) : (
          <Typography variant="subtitle1">
            Aún no hay fincas registradas
          </Typography>
        )}
      </List>
    </Paper>
  );
};

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ProductData {
  id: number;
  block: string;
  variety: string;
  roles: string[];
  loteCodeRule: null | string;
  sproutsDescription: null | string;
  regulatedProduct: {
    id: number;
    name: string;
    variety: string;
    imageUrl: string;
  };
}

interface ProductCardProps {
  product: ProductData;
  onEdit: (product: ProductData) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onEdit,
}: ProductCardProps) => {
  const {
    id,
    block,
    variety,
    roles,
    regulatedProduct: { name, variety: type, imageUrl },
  } = product;
  const navigate = useNavigate();

  type TypographyVariant =
    | "subtitle2"
    | "h6"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "inherit"
    | "subtitle1"
    | "body1"
    | "overline";

  const cardContentItems: {
    variant: TypographyVariant;
    color: string;
    text: string;
    gutterBottom?: boolean;
  }[] = [
    { variant: "subtitle2", color: "text.secondary", text: type.toUpperCase() },
    {
      variant: "h6",
      color: "text.primary",
      text: name.toUpperCase(),
      gutterBottom: true,
    },
    { variant: "body2", color: "text.primary", text: `Cuartel: ${block}` },
    { variant: "body2", color: "text.primary", text: `Variedad: ${variety}` },
    {
      variant: "body2",
      color: "text.primary",
      text: `Roles: ${roles.join(", ")}`,
    },
  ];

  const goToApplication = () => {
    navigate(`/application/${id}/0/0`);
  };

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: "100%",
        width: "100%",
        position: "relative",
        backgroundColor: "#F4F4F4",
      }}
      key={id}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box position="absolute" top={0} right={0}>
          <Button
            size="small"
            onClick={() => onEdit(product)}
            sx={{
              color: "#093BA0",
            }}
          >
            Editar
          </Button>
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <CardMedia
              component="img"
              sx={{ width: "100%", height: "auto" }}
              image={imageUrl}
              alt={name}
            />
          </Grid>
          <Grid item xs={8}>
            <CardContent>
              {cardContentItems.map((item, index) => (
                <Typography
                  key={index}
                  variant={item.variant}
                  color={item.color}
                  gutterBottom={item.gutterBottom}
                >
                  {item.text}
                </Typography>
              ))}
            </CardContent>
          </Grid>
        </Grid>
        <CardActions sx={{ justifyContent: "center", paddingBottom: 2 }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
              backgroundColor: "#093BA0",
              color: "#FFFFFF",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#093BA0",
              },
            }}
            onClick={goToApplication}
          >
            Ingresar Solicitud
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};

export default ProductCard;

import { Box, Typography, useTheme } from '@mui/material';

export const HeroSectionBodyBlanck: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        height: '80vh',
        backgroundColor: '#e6ebf5',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]: {
          height: '70vh',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          textAlign: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          padding: 20,
          [theme.breakpoints.down('sm')]: {
            padding: 5,
          },
        }}
      >
        <Typography
          variant='h4'
          component='div'
          sx={{
            my: 2,
            mr: 2,
            mt: 10,
            maxWidth: '600px',
            textAlign: 'justify',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5rem',
              mt: 5,
            },
          }}
          color='#093BA0'
        >
          En FSK te ayudamos con el cumplimiento de la{' '}
          <strong> nueva regla FSMA 204, exigida por Estados Unidos, </strong>{' '}
          para múltiples frutas, verduras, mariscos y pescados.
        </Typography>
        <Box
          component={'img'}
          src={'img/istockphoto-1471520957-612x6121.png'}
          alt='istock'
          sx={{
            width: { xs: '100%', md: '50%' },
            height: 'auto',
          }}
        />
      </Box>
    </Box>
  );
};

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Box, Hidden, useTheme } from '@mui/material';
import { Logofsk } from '../icons/logofsk';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

export const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <AppBar
      color='inherit'
      // sx={{
      //   [theme.breakpoints.down('sm')]: {
      //     minHeight: '56px',
      //     width: '100%', // Cambiado de maxWidth a width
      //   },
      // }}
    >
      <Toolbar
        sx={{
          [theme.breakpoints.down('sm')]: {
            minHeight: '56px',
            maxWidth: '100%',
            padding: '0 1em',
          },
        }}
      >
        <Box
          component='div'
          sx={{
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: { maxWidth: 300 },
          }}
        >
          <Logofsk color={'#093BA0'} height='100' width='100' />
        </Box>
        <Hidden smDown>
          <Box component='div' sx={{ flexGrow: 1 }}>
            <Button
              sx={{
                color: '#093BA0',
                borderRadius: '4px',
                margin: 2,
              }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}
              >
                <HomeOutlinedIcon />
              </Box>
              Home
            </Button>
            <Button
              sx={{
                color: '#093BA0',
                borderRadius: '4px',
                margin: 2,
              }}
              onClick={() => scrollToSection('Nosotros')}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}
              >
                <PersonOutlinedIcon />
              </Box>
              Nosotros
            </Button>
            <Button
              sx={{
                color: '#093BA0',
                borderRadius: '4px',
                margin: 2,
              }}
              onClick={() => scrollToSection('contact')}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}
              >
                <AddBoxOutlinedIcon />
              </Box>
              Contacto
            </Button>
          </Box>
        </Hidden>
        <Button
          sx={{
            bgcolor: '#093BA0',
            color: 'white',
            borderRadius: '10px',
            marginLeft: 1,
            width: '12.5em',
            '&:hover': {
              bgcolor: '#093BA0',
              boxshadow: '0px 8px 15px rgba(0, 0, 0, 1)',
            },
            [theme.breakpoints.down('sm')]: {
              width: 'auto',
              fontSize: '0.8rem',
            },
          }}
          onClick={() => navigate('/Login')}
        >
          Ingresar
        </Button>
      </Toolbar>
    </AppBar>
  );
};

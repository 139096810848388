import React from 'react';
import { Button, Box } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

//TODO: Modularize the AddOrCancelButton component
interface AddOrCancelButtonProps {
  toggle: () => void;
  isActive : boolean;
}

const AddOrCancelButton: React.FC<AddOrCancelButtonProps> = ({ toggle, isActive }) => {

  const handleClick = () => {
    toggle();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      <Button
        sx={{
          textTransform: 'none',
          color: '#093BA0',
          '&:hover': {
            backgroundColor: '#F4F4F4',
          },
          fontSize: '1.1rem',
        }}
        startIcon={isActive ? <CancelOutlinedIcon sx={{ width: '26px', height: '26px' }} />
        : <AddCircleOutlineRoundedIcon sx={{ width: '26px', height: '26px' }} />}
        onClick={handleClick}
      >
        {isActive ? 'Cancelar' : 'Agregar nueva finca'}
      </Button>
    </Box>
  );
};

export default AddOrCancelButton;
import { Box, Typography, useTheme } from '@mui/material';

export const HeroSectionBodyIcons: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        backgroundColor: '#e6ebf5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          height: '150vh',
        },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '800px',
          p: 10,
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            p: 2,
          },
        }}
      >
        <Typography
          variant='h3'
          component='div'
          sx={{
            my: 2,
            color: '#093BA0',
            fontWeight: 'bold',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5rem',
            },
          }}
        >
          En FSK estamos para ayudarte en este nuevo proceso
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            mt: 10,
            mb: 10,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: 5,
            },
          }}
        >
          <img src='img/Group 404.png' alt='Group 404' />
          <img src='img/Group 405.png' alt='Group 405' />
          <img src='img/Group 406.png' alt='Group 406' />
        </Box>
      </Box>
    </Box>
  );
};

import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
  Grid,
} from '@mui/material';
import EditOutlined from '@mui/icons-material/EditOutlined';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import { ExploreOutlined } from '@mui/icons-material';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';

interface Sector{
  id: number;
  sectorName: string;
}

interface FarmData{
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  imageUrl: string;
  sectors: Sector[];
}

interface FarmCardProps {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  imageUrl: string;
  id: string;
  sectors: Sector[];
  onEdit: (farm: FarmData) => void;
}

export const FarmCard : React.FC <FarmCardProps> = ({
  name,
  address,
  latitude,
  longitude,
  imageUrl,
  id,
  sectors,
  onEdit,
}) => {
  const coordinates = `Lat: ${latitude} - Long: ${longitude}`;

  return (
    <Grid item xs={12} md={8}>
      <Card
        sx={{
          position: 'relative',
          display: 'flex',
          marginBottom: 2,
          backgroundColor: '#F4F4F4',
          maxWidth: 1000,
          margin: 'left',
        }}
      >
        <CardMedia
          component='img'
          sx={{ width: 151 }}
          image={imageUrl}
          alt={`Imagen de ${name}`}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography component='div' variant='h5' sx={{mb:2}}>
              {name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <LocationOnOutlined sx={{ color: '#093BA0', mr:0.3 }} />
              <Typography
                variant='subtitle1'
                color='text.secondary'
                component='div'
              >
                {address}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <ExploreOutlined sx={{ color: '#093BA0', mr:0.4 }} />
              <Typography
                variant='subtitle1'
                color='text.secondary'
                component='div'
              >
                {coordinates}
              </Typography>
            </Box>
            {sectors && sectors.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <ViewComfyOutlinedIcon sx={{ color: '#093BA0', mr:0.4 }} />
              <Typography
                variant='subtitle1'
                color='text.secondary'
                component='div'
              >
                {"Sectores: " + sectors.map((sector) => sector.sectorName).join(', ')}
              </Typography>
            </Box>}
          </CardContent>
        </Box>
        <Button 
          startIcon={<EditOutlined />} 
          size='small'
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            color: '#093BA0',
          }}
          onClick={() => onEdit({ 
            id, 
            name, 
            address, 
            latitude, 
            longitude, 
            imageUrl,
            sectors, 
          })}
        >
          Editar datos
        </Button>
      </Card>
    </Grid>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { HomeNavbar } from '../components/HomeNavbar';
import { Box, Grid, Typography, Alert, CircularProgress } from '@mui/material';
import { useAuth } from '../context/authContext';
import ProductCard from '../components/product/ProductCard';
import Divider from '@mui/material/Divider';
import AddOrCancelButton from '../components/product/ProductAddButton';
import ProductForm from '../components/product/ProductForm';


interface ProductData {
  id: number;
  block: string;
  variety: string;
  roles: string[];
  loteCodeRule: null | string;
  sproutsDescription: null | string;
  regulatedProduct: {
    id: number;
    name: string;
    variety: string;
    imageUrl: string;

  }
}

export const Product: React.FC = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false); //Change when connected to backend
  const [error, setError] = useState<string>('');
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [editingProduct, setEditingProduct] = useState<ProductData | null>(null);
  const companyId = auth?.authState.user?.company;
  const token = auth?.authState.token;

  const toggleForm = () => {
    setFormVisible(!formVisible);
    setEditingProduct(null);
  };

  const handleEdit = (product: ProductData) => {
    setEditingProduct(product);
    setFormVisible(true);
  }


  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/companyProducts/company/${companyId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Error al obtener los productos');
      }
      let data = await response.json();

      // If is not an array, convert to array
      if (!Array.isArray(data))
        data = [data];

      setProducts(data)
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [companyId, token]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  //TODO: Refactor to modularize Layout with Navbar, Title and Subtitle
  return (
    <Box sx={{ flexGrow: 1, m: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} marginBottom={10}>
          <HomeNavbar />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h5'
            component='h1'
            alignItems='center'
            textAlign='center'
          >
            Mis Productos
          </Typography>
          <Typography
            variant='h6'
            component='h1'
            alignItems='center'
            textAlign='center'
            color='#A6A6A6'
          >
            Revisa tus productos ingresados
          </Typography>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h6'
            component='h2'
            gutterBottom
            sx={{
              fontSize: '20px',
              color: '#093BA0',
              fontWeight: 'bold',
            }}
          >
            Productos Agregados
          </Typography>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity='error'>{error}</Alert>
          </Grid>
        )}
        {isLoading ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : products.length > 0 ? (
          products.map((product) => (
            <Grid
              item
              xs={12} sm={6} md={4} lg={3}
              key={product.id}
              sx={
                {
                  padding: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: 'none',
                  flexBasis: 'auto',
                }
              }
            >
              <ProductCard product={product} onEdit={() => handleEdit(product)} />
            </Grid>
          ))
        ) : (!error) && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              textAlign="left"
              sx={{ ml: 1 }}
            >
              Aún no has agregado productos.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <AddOrCancelButton toggle={toggleForm} isActive={formVisible} />
        </Grid>

        {formVisible && !error && (
          <Grid item xs={12}>
            <ProductForm toggle={toggleForm} editingProduct={editingProduct} fetchProducts={fetchProducts} />
          </Grid>
        )}

      </Grid>
    </Box>
  );
};
let step2InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
  prefillArrayNumber: number[]; //el step donde se va a hacer prefill. si no hay prefill es un arreglo vacio
  prefillPosition: number[]; //la posicion en el arreglo del input donde se va a hacer el prefill . si no hay prefill es un arreglo vacio
  //cada posicion del arreglo prefillArrayNumber y del prefillPosition estan relacionados, por lo que deben tener el mismo largo
}[] = [
  {
    key: "1_1325a1i",
    input_id: 0,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.1 Descripción del destinatario  del alimento cosechado",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la dirección y nombre del destinatario",
    prefillArrayNumber: [],
    prefillPosition: [],
  },
  {
    key: "1_1325a1iii",
    input_id: 1,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.3 Cantidad y unidad de medida del alimento (p.ej., 75 contenedores, 200 Kilos)",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
    prefillArrayNumber: [],
    prefillPosition: [],
  },
  {
    key: "1_1325a1iv",
    input_id: 2,
    input_type: 3, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.4 Lugar de la Finca donde se cosechó",
    options: ["lugar1", "lugar2", "lugar3"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el lugar según mapa",
    prefillArrayNumber: [4, 3],
    prefillPosition: [3, 4],
  },
  {
    key: "1_1325a1vii",
    input_id: 3,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.7 Fecha de cosecha",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
    prefillArrayNumber: [],
    prefillPosition: [],
  },
  {
    key: "1_1325a1viii",
    input_id: 4,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.8 Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
    prefillArrayNumber: [],
    prefillPosition: [],
  },
];

export default step2InputsArray;

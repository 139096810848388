import React, { createContext, useState, useContext, useEffect } from "react";

interface User {
  id: string;
  name: string;
  email: string;
  company: string;
  companyName: string;
}
interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

interface AuthContextType {
  authState: AuthState;
  loginUser: (userData: { user: User; token: string }) => void;
  logoutUser: () => void;
  updateUserEmail: (email: string) => Promise<void>;
  ValidateIfUserIsAuthenticated: () => Promise<boolean>;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    user: null,
    token: null,
  });

  useEffect(() => {
    const fetchSession = async () => {
      const storedSession = JSON.parse(
        (await localStorage.getItem("userSession")) || "{}"
      );
      const currentTime = new Date().getTime();

      if (
        storedSession.token &&
        storedSession.user &&
        storedSession.expirationTime > currentTime
      ) {
        setAuthState({
          isAuthenticated: true,
          user: storedSession.user,
          token: storedSession.token,
        });
      } else {
        localStorage.removeItem("userSession");
      }

      setIsLoading(false);
    };
    fetchSession();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // O cualquier otro componente de "carga" que quieras mostrar
  }

  const loginUser = (userData: { user: User; token: string }) => {
    const expirationTime = new Date().getTime() + 60 * 60 * 8000;
    const userSession = { ...userData, expirationTime };
    localStorage.setItem("userSession", JSON.stringify(userSession));
    setAuthState((prevState: AuthState) => ({
      ...prevState,
      isAuthenticated: true,
      user: userData.user,
      token: userData.token,
    }));
  };

  const logoutUser = () => {
    localStorage.removeItem("userSession");
    setAuthState({
      ...authState,
      isAuthenticated: false,
      user: null,
      token: null,
    });
  };

  const updateUserEmail = (email: string) => {
    return new Promise<void>((resolve) => {
      setAuthState((prevState) => {
        if (prevState.user) {
          const updatedState = {
            ...prevState,
            user: { ...prevState.user, email },
          };
          localStorage.setItem(
            "userSession",
            JSON.stringify({ user: updatedState.user, token: prevState.token })
          );
          return updatedState;
        }
        return prevState;
      });
      resolve();
    });
  };

  const ValidateIfUserIsAuthenticated = async () => {
    const storedSession = JSON.parse(
      (await (localStorage.getItem("userSession") || "{}")) as string
    );
    if (authState.isAuthenticated || storedSession.token) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        loginUser,
        logoutUser,
        updateUserEmail,
        ValidateIfUserIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import * as React from "react";
import styles from "./StandardMultipleOptionsInput.style.module.css";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
interface ApplicationStep1Props {
  arrayPosition: number;
  upperText: string;
  defaultValue: string[];
  setValueInArray: (value: string, position: number) => void;
  setMultipleValuesInArray: (value: string[], position: number) => void;
  inputOptions: string[];
  inputLabel: string;
}

export const StandardMultipleOptionsInput = ({
  upperText,
  setValueInArray,
  setMultipleValuesInArray,
  arrayPosition,
  defaultValue,
  inputOptions,
  inputLabel,
}: ApplicationStep1Props) => {
  //const [inputDefaultValue, setInputDefaultValue] = useState(defaultValue); //idea para despues
  const [optionName, setOptionName] = React.useState<string[]>(defaultValue);
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof optionName>) => {
    const {
      target: { value },
    } = event;
    setOptionName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setMultipleValuesInArray(
      typeof value === "string" ? value.split(",") : value,
      arrayPosition
    );
    let stringifiedValue = JSON.stringify(value);
    let result = stringifiedValue
      .slice(1, stringifiedValue.length - 1)
      .replace(/"/g, "");
    setValueInArray(result, arrayPosition);
  };

  return (
    <>
      <div className={styles.textfieldContainer}>
        <p className={styles.upperText}>{upperText}</p>
        <FormControl sx={{ m: 0, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            {inputLabel}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={optionName}
            onChange={handleChange}
            input={<OutlinedInput label={inputLabel} />}
            renderValue={(selected: any) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {inputOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={optionName.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

/* eslint-disable array-callback-return */
import * as React from "react";
import styles from "./ApplicationStep1.style.module.css";
import { useState } from "react";
import { StandardInput } from "../../reusables/StandardInput/StandardInput";
import { StandardButton } from "../../reusables/StandardButton/StandardButton";
import { StandardCombobox } from "../../reusables/StandardCombobox/StandardCombobox";
import { CalendarInput } from "../../reusables/CalendarInput/CalendarInput";
import { StandardMultipleOptionsInput } from "../../reusables/StandardMultipleOptionsInput/StandardMultipleOptionsInput";
import { useParams } from "react-router-dom";

interface ApplicationStep1Props {
  setStep: (value: number) => void;

  setFarmIDSelected: () => void;
  step1InputsArray: any[];
  productData: any;
  farmOptions: any;
  socialRoleOptions: any;
  rolesOptions: any;
}

export const ApplicationStep1 = ({
  setStep,
  step1InputsArray,
  productData,
  farmOptions,
  socialRoleOptions,
  rolesOptions,
  setFarmIDSelected,
}: ApplicationStep1Props) => {
  const [, /*fincaDefaultValue*/ setfincaDefaultValue] = useState(""); //se puede necesitar a futuro
  const { productId } = useParams();

  const setValueInArray = (inputValue: string, arrayPosition: number) => {
    step1InputsArray[arrayPosition].input_value = inputValue;
    if (arrayPosition === 0) {
      setfincaDefaultValue(inputValue);
    }
    if (arrayPosition === 1) {
      setfincaDefaultValue(inputValue);
    }
  };

  const findInStringArray = (strArray: string[], value: string) => {
    let result = false;
    strArray?.map((str: string) => {
      if (str === value) result = true;
    });
    return result;
  };

  const findNextStep = () => {
    if (findInStringArray(productData?.roles, "Cosecha")) {
      setStep(2);
      return;
    }
    if (findInStringArray(productData?.roles, "Enfría")) {
      setStep(3);
      return;
    }
    if (findInStringArray(productData?.roles, "Empaca")) {
      if (findInStringArray(productData?.roles, "Brote")) {
        setStep(4.1);
        return;
      } else {
        setStep(4);
        return;
      }
    }
    if (findInStringArray(productData?.roles, "Recibe de Buque en tierra")) {
      setStep(5);
      return;
    }
    if (findInStringArray(productData?.roles, "Despacha")) {
      setStep(6);
      return;
    }
    if (findInStringArray(productData?.roles, "Recibe")) {
      setStep(7);
      return;
    }
    if (findInStringArray(productData?.roles, "Transformador")) {
      setStep(8);
      return;
    }
    //FINAL STEP
    setStep(99);
    return;
  };

  const setMultipleValuesInArray = (
    inputValue: string[],
    arrayPosition: number
  ) => {
    step1InputsArray[arrayPosition].input_multiple_values = inputValue;
  };

  const ArrayPrinter = () => {
    //la variable arrayOfInputs tendra un conjunto de HTMLS en cada posicion del arreglo y cada uno de ellos sera un input imprimido dinamicamente, mediante lo que este en el "inputsArray.tsx de la misma carpeta que este archivo"
    let arrayOfInputs: any = [];
    const [, /*value*/ setValue] = useState(""); //puede usarse a futuro
    step1InputsArray.map((index: any) => {
      if (index.hide === false) {
        if (index.input_type === 0) {
          arrayOfInputs[index.input_id] = (
            <div className={styles.singleInputContainer}>
              <StandardInput
                setValue={setValue}
                upperText={index.upper_text}
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, index.input_id)
                }
                arrayPosition={index.input_id}
                label={index.input_label}
                defaultValue={index.input_value}
              />
            </div>
          );
        } else if (index.input_type === 1) {
          arrayOfInputs[index.input_id] = (
            <div className={styles.singleInputContainer}>
              <StandardCombobox
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, index.input_id)
                }
                arrayPosition={index.input_id}
                upperText={index.upper_text}
                options={index.options}
                label={index.input_label}
                defaultValue={index.input_value}
              />
            </div>
          );
        } else if (index.input_type === 2) {
          arrayOfInputs[index.input_id] = (
            <div className={styles.singleInputContainer}>
              <CalendarInput
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, index.input_id)
                }
                arrayPosition={index.input_id}
                upperText={index.upper_text}
                defaultValue={index.input_value}
              />
            </div>
          );
        } else if (index.input_type === 3) {
          arrayOfInputs[index.input_id] = (
            <div className={styles.singleInputContainer}>
              <StandardMultipleOptionsInput
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, index.input_id)
                }
                setMultipleValuesInArray={(value: string[], position: number) =>
                  setMultipleValuesInArray(value, index.input_id)
                }
                arrayPosition={index.input_id}
                upperText={index.upper_text}
                defaultValue={index.input_multiple_values}
                inputOptions={index.options}
                inputLabel={index.input_label}
              />
            </div>
          );
        }
      }
    });

    return arrayOfInputs;
  };

  return (
    <>
      <div className={styles.step1Container}>
        <h2 className={styles.titleText}>Regulación</h2>
        <p className={styles.subtitleText}>
          Ingresa una nueva solicitud sobre tu Lote
        </p>
        <div className={styles.stepInformationContainer}>
          <h2 className={styles.informationMainTitleText}>Datos básicos</h2>
          <p className={styles.informationTitleText}>
            Ingresa los datos relacionados a:{" "}
            <i>{productData?.regulatedProduct?.name}</i>
          </p>
          <div className={styles.inputsContainer}>
            <div className={styles.singleInputContainer}>
              <StandardCombobox
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, 0)
                }
                arrayPosition={0}
                upperText={"a.5 Asigna una finca o fábrica"}
                options={farmOptions}
                label={"Selecciona tu finca"}
                defaultValue={step1InputsArray[0].input_value}
              />
            </div>
            <div className={styles.singleInputContainer}>
              <StandardCombobox
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, 1)
                }
                arrayPosition={1}
                upperText={"Razón social relacionada"}
                options={socialRoleOptions}
                label={"Selecciona tus razones sociales"}
                defaultValue={step1InputsArray[1].input_value}
              />
            </div>
            <div className={styles.singleInputContainer}>
              <StandardMultipleOptionsInput
                setValueInArray={(value: string, position: number) =>
                  setValueInArray(value, 2)
                }
                setMultipleValuesInArray={(value: string[], position: number) =>
                  setMultipleValuesInArray(value, 2)
                }
                arrayPosition={2}
                upperText={"Selecciona tu rol para esta regulación"}
                defaultValue={step1InputsArray[2].input_multiple_values}
                inputOptions={rolesOptions}
                inputLabel={"Seleccionar roles a regular"}
              />
            </div>
            {ArrayPrinter()}
          </div>
          <div className={styles.buttonContainer}>
            <StandardButton
              executableFunction={() => {
                if (productId === "0") {
                  setStep(2);
                } else {
                  setFarmIDSelected();
                  findNextStep();
                }

                //setStep(2);
              }}
              innerText="Siguiente"
            />
          </div>
        </div>
      </div>
    </>
  );
};

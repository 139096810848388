import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface DeleteFarmDialogProps {
  isOpen : boolean;
  onClose : () => void;
  onDelete : () => void;
}

//TODO: Modularize dialogs
const DeleteFarmDialog: React.FC<DeleteFarmDialogProps> = ({ isOpen, onClose, onDelete }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirmar Eliminación"}  
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Está seguro que desea eliminar la finca? Esta acción no se puede deshacer.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button 
          onClick={onClose} 
          variant='contained'
          sx={{
            marginRight: '10px',
            borderRadius: '30px',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            color: '#000000',
            paddingX: '30px',
            textTransform: 'none',
            fontSize: '1rem',
            '&:hover': {
              backgroundColor: '#F4F4F4',
              borderColor: 'transparent',
              color: '#000000',
            },
          }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={onDelete} 
          variant="contained" 
          autoFocus
          sx={{
            borderRadius: '30px',
            backgroundColor: '#093BA0',
            color: '#FFFFFF',
            paddingX: '30px',
            textTransform: 'none',
            fontSize: '1rem',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: '#093BA0',
            },
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>  
  );
};

export default DeleteFarmDialog;
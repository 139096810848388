/* eslint-disable array-callback-return */
import * as React from "react";
import styles from "./StepFinal.style.module.css";
import { StandardButton } from "../../reusables/StandardButton/StandardButton";
import { SecondaryButton } from "../../reusables/SecondaryButton/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";

interface StepFinalProps {
  setStep: (value: number) => void;
  uploadData: () => void;
  productData: any;
  formClear: () => void;
  findAndSetSubcompanyID: () => void;
  isPrefill: boolean;
}

export const StepFinal = ({
  setStep,
  uploadData,
  productData,
  formClear,
  findAndSetSubcompanyID,
  isPrefill,
}: StepFinalProps) => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const findInStringArray = (strArray: string[], value: string) => {
    let result = false;
    strArray?.map((str: string) => {
      if (str === value) result = true;
    });
    return result;
  };
  const findPreviousStep = () => {
    if (findInStringArray(productData?.roles, "Transformador")) {
      setStep(8);
      return;
    }
    if (findInStringArray(productData?.roles, "Recibe")) {
      setStep(7);
      return;
    }
    if (findInStringArray(productData?.roles, "Despacha")) {
      setStep(6);
      return;
    }
    if (findInStringArray(productData?.roles, "Recibe de Buque en tierra")) {
      setStep(5);
      return;
    }
    if (findInStringArray(productData?.roles, "Empaca")) {
      if (findInStringArray(productData?.roles, "Brote")) {
        setStep(4.1);
        return;
      } else {
        setStep(4);
        return;
      }
    }
    if (findInStringArray(productData?.roles, "Enfría")) {
      setStep(3);
      return;
    }
    if (findInStringArray(productData?.roles, "Cosecha")) {
      setStep(2);
      return;
    }
    setStep(1);
    return;
  };

  return (
    <>
      <div className={styles.step1Container}>
        <h2 className={styles.titleText}>Regulación</h2>
        <p className={styles.subtitleText}>
          Ingresa una nueva solicitud sobre tu Lote
        </p>
        <div className={styles.stepInformationContainer}>
          <p className={styles.informationTitleText}>
            ¡Has finalizado la solicitud del producto
            <i> {productData?.regulatedProduct?.name}</i>!
          </p>
          {!isPrefill && (
            <p className={styles.smallText}>
              Presiona "Guardar" para finalizar el proceso, o "Atrás" para
              revisar los datos introducidos.
            </p>
          )}
          <div className={styles.inputsContainer}></div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              executableFunction={() => {
                if (productId === "0") {
                  setStep(8);
                } else {
                  findPreviousStep();
                }
              }}
              innerText="Atrás"
            />

            <StandardButton
              executableFunction={() => {
                if (productId === "0") {
                } else {
                  findAndSetSubcompanyID();
                  uploadData();
                }

                formClear(); //ANTES DE NAVEGAR SE DEBE SETEAR TODOS LOS ARREGLOS EN VACIO
                navigate("/products");
              }}
              innerText={isPrefill ? "Volver al seguimiento" : "Guardar"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

import { Box, Typography, useTheme } from '@mui/material';

interface props {
  url: string;
}

export const HeroSectionTop: React.FC<props> = ({ url }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        height: '100vh',
        backgroundImage: url,
        backgroundPosition: 'center',
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          p: 10,
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            p: 2,
          },
        }}
      >
        <Typography
          variant='h2'
          component='div'
          color='#093BA0'
          fontWeight='bold'
          sx={{
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5rem',
            },
          }}
        >
          Simplifica tu compliance exportador
        </Typography>
        <Typography
          variant='h4'
          component='div'
          sx={{
            my: 2,
            maxWidth: '800px',
            [theme.breakpoints.down('sm')]: { fontSize: '1rem' },
          }}
          color='#093BA0'
        >
          Continúa exportando a Estados Unidos cumpliendo sus nuevas
          regulaciones
        </Typography>
      </Box>
    </Box>
  );
};

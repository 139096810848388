import { Box, Typography } from '@mui/material';

interface props {
  url: string;
}

export const HeroSectionBody: React.FC<props> = ({ url }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '80%',
        backgroundImage: url,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          pt: 10,
          px: { xs: 2, sm: 4, md: 20 },
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant='h3'
          component='div'
          sx={{
            my: 2,
            mb: 5,
            fontSize: { xs: '1.8rem', sm: '2rem', md: '2.4rem' },
          }}
          color='#093BA0'
          fontWeight='bold'
        >
          ¿Qué es la nueva regla FSMA 204?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'left',
            mt: 5,
            mb: 5,
          }}
        >
          <Typography
            variant='h1'
            component='div'
            sx={{
              my: 2,
              mr: 2,
              fontSize: { xs: '1.9rem', sm: '2.2rem', md: '2.4rem' },
            }}
            color='#093BA0'
            fontWeight='bold'
          >
            1.
          </Typography>
          <Typography
            variant='h5'
            component='div'
            sx={{
              my: 2,
              justifyContent: 'left',
              fontWeight: 'lighter',
              maxWidth: { sm: '600px', xs: '100%' },
              textAlign: 'justify',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
            }}
            color='#093BA0'
          >
            Una nueva norma que exige que los diferentes actores de la cadena de
            suministro mantengan registros de los alimentos incluidos en la
            Lista de Trazabilidad de alimentos.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
            flexDirection: { xs: 'column', sm: 'row' },
            mb: 5,
          }}
        >
          <Typography
            variant='h1'
            component='div'
            sx={{
              my: 2,
              mr: 2,
              fontSize: { xs: '1.9rem', sm: '2.2rem', md: '2.4rem' },
            }}
            color='#093BA0'
            fontWeight='bold'
          >
            2.
          </Typography>
          <Typography
            variant='h5'
            component='div'
            sx={{
              my: 2,
              maxWidth: { sm: '600px', xs: '100%' },
              fontWeight: 'lighter',
              textAlign: 'justify',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
            }}
            color='#093BA0'
          >
            Busca simplificar y hacer más eficaz y precisa la trazabilidad de
            los alimentos potencialmente contaminados que son distribuidos y
            vendidos en Estados Unidos.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left',
            flexDirection: { xs: 'column', sm: 'row' },
            mb: 5,
          }}
        >
          <Typography
            variant='h1'
            component='div'
            sx={{
              my: 2,
              mr: 2,
              fontSize: { xs: '1.9rem', sm: '2.2rem', md: '2.4rem' },
            }}
            color='#093BA0'
            fontWeight='bold'
          >
            3.
          </Typography>
          <Typography
            variant='h5'
            component='div'
            sx={{
              my: 2,
              maxWidth: { sm: '600px', xs: '100%' },
              fontWeight: 'lighter',
              textAlign: 'justify',
              fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' },
            }}
            color='#093BA0'
          >
            Esta compuesta por hasta 108 variables regulatorias de trazabilidad
            de la cadena de suministro, como geolocalización de tus campos,
            exigiendo también la mantención de estos registros por hasta 2 años.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

let step7InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
}[] = [
  {
    key: "1_1345a2",
    input_id: 0,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.2 La cantidad y la unidad de medida del alimento (p.ej., 6 cajas, 25 contenedores de plástico reutilizables, 100 tanques, 200 libras);",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1345a5",
    input_id: 1,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.5 Lugar donde se recibió el alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Nombre y dirección",
  },
  {
    key: "1_1345a6",
    input_id: 2,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.6 Fecha de recepción del alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione fecha",
  },
  {
    key: "1_1345a7",
    input_id: 3,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.7 Descripción de la fuente del código de trazabilidad",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Describa el lugar donde despacha",
  },
  {
    key: "1_1345a8",
    input_id: 4,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.8 Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
  },
];

export default step7InputsArray;

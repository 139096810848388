import * as React from "react";
import styles from "./StandardButton.style.module.css";

interface ApplicationStep1Props {
  executableFunction: () => any;
  innerText: string;
}

export const StandardButton = ({
  executableFunction,
  innerText,
}: ApplicationStep1Props) => {
  return (
    <>
      <div
        className={styles.buttonContainer}
        onClick={() => executableFunction()}
      >
        <p className={styles.buttonText}>{innerText}</p>
      </div>
    </>
  );
};

/* eslint-disable array-callback-return */
import * as React from "react";
import styles from "./ApplicationStep4_1.style.module.css";
import { useState } from "react";
import { StandardInput } from "../../reusables/StandardInput/StandardInput";
import { StandardButton } from "../../reusables/StandardButton/StandardButton";
import { StandardCombobox } from "../../reusables/StandardCombobox/StandardCombobox";
import { SecondaryButton } from "../../reusables/SecondaryButton/SecondaryButton";
import { StandardMultipleOptionsInput } from "../../reusables/StandardMultipleOptionsInput/StandardMultipleOptionsInput";
import { CalendarInput } from "../../reusables/CalendarInput/CalendarInput";

interface ApplicationStep4_1Props {
  setStep: (value: number) => void;
  step4_1InputsArray: any[];
  productData: any;
}

export const ApplicationStep4_1 = ({
  setStep,
  step4_1InputsArray,
  productData,
}: ApplicationStep4_1Props) => {
  const setValueInArray = (inputValue: string, arrayPosition: number) => {
    step4_1InputsArray[arrayPosition].input_value = inputValue;
  };

  const setMultipleValuesInArray = (
    inputValue: string[],
    arrayPosition: number
  ) => {
    step4_1InputsArray[arrayPosition].input_multiple_values = inputValue;
  };

  const findInStringArray = (strArray: string[], value: string) => {
    let result = false;
    strArray?.map((str: string) => {
      if (str === value) result = true;
    });
    return result;
  };
  const findPreviousStep = () => {
    if (findInStringArray(productData?.roles, "Enfría")) {
      setStep(3);
      console.log("se va al paso 3");
      return;
    }
    if (findInStringArray(productData?.roles, "Cosecha")) {
      setStep(2);
      console.log("se va al paso 2");
      return;
    }
    console.log("se va al paso 1");
    setStep(1);
    return;
  };

  const findNextStep = () => {
    if (findInStringArray(productData?.roles, "Recibe de Buque en tierra")) {
      setStep(5);
      console.log("se va al paso 5");
      return;
    }
    if (findInStringArray(productData?.roles, "Despacha")) {
      setStep(6);
      console.log("se va al paso 6");
      return;
    }
    if (findInStringArray(productData?.roles, "Recibe")) {
      setStep(7);
      console.log("se va al paso 7");
      return;
    }
    if (findInStringArray(productData?.roles, "Transformador")) {
      setStep(8);
      console.log("se va al paso 8");
      return;
    }
    //FINAL STEP
    console.log("se va al paso final");
    setStep(99);
    return;
  };

  const ArrayPrinter = () => {
    //la variable arrayOfInputs tendra un conjunto de HTMLS en cada posicion del arreglo y cada uno de ellos sera un input imprimido dinamicamente, mediante lo que este en el "inputsArray.tsx de la misma carpeta que este archivo"
    let arrayOfInputs: any = [];
    const [, /*value*/ setValue] = useState("");
    step4_1InputsArray.map((index: any) => {
      if (index.input_type === 0) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <StandardInput
              setValue={setValue}
              upperText={index.upper_text}
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              label={index.input_label}
              defaultValue={index.input_value}
            />
          </div>
        );
      } else if (index.input_type === 1) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <StandardCombobox
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              upperText={index.upper_text}
              options={index.options}
              label={index.input_label}
              defaultValue={index.input_value}
            />
          </div>
        );
      } else if (index.input_type === 2) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <CalendarInput
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              upperText={index.upper_text}
              defaultValue={index.input_value}
            />
          </div>
        );
      } else if (index.input_type === 3) {
        arrayOfInputs[index.input_id] = (
          <div className={styles.singleInputContainer}>
            <StandardMultipleOptionsInput
              setValueInArray={(value: string, position: number) =>
                setValueInArray(value, index.input_id)
              }
              setMultipleValuesInArray={(value: string[], position: number) =>
                setMultipleValuesInArray(value, index.input_id)
              }
              arrayPosition={index.input_id}
              upperText={index.upper_text}
              defaultValue={index.input_multiple_values}
              inputOptions={index.options}
              inputLabel={index.input_label}
            />
          </div>
        );
      }
    });

    return arrayOfInputs;
  };

  return (
    <>
      <div className={styles.step1Container}>
        <h2 className={styles.titleText}>Regulación</h2>
        <p className={styles.subtitleText}>
          Ingresa una nueva solicitud sobre tu Lote
        </p>
        <div className={styles.stepInformationContainer}>
          <p className={styles.informationTitleText}>
            4.1Ingresa los datos relacionados a:{" "}
            <i>{productData?.regulatedProduct?.name}</i>
          </p>
          <div className={styles.inputsContainer}>{ArrayPrinter()}</div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              executableFunction={() => findPreviousStep()}
              innerText="Atrás"
            />
            <StandardButton
              executableFunction={() => findNextStep()}
              innerText="Siguiente"
            />
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState } from 'react';
import {
  Button,
  TextField,
  IconButton,
  Grid,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../context/authContext';
import { useSnackbar } from '../../context/snackbarContext';
import DeleteSectorDialog from './DeleteSectorDialog';

interface Sector {
  id: number;
  sectorName: string;
}

interface SectorFormProps {
  sectors: Sector[];
  setSectors: React.Dispatch<React.SetStateAction<Sector[]>>;
}

const SectorForm: React.FC<SectorFormProps> = ({ sectors, setSectors }) => {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number>(0);
  const token = auth?.authState.token;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newSectors = [...sectors];
    newSectors[index].sectorName = event.target.value;
    setSectors(newSectors);
  }

  const handleAdd = () => {
    const newSectors = [...sectors];
    newSectors.push({ id: 0, sectorName: '' });
    setSectors(newSectors);
  }

  //Dialog handling
  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  //Delete handling

  //Check if the sector is new or already exists
  const handleDeleteConfirmation = (index: number) => {
    //If the sector is new, delete it
    if (sectors[index].id === 0) {
      handleDelete(index);
      return;
    }
    //If the sector already exists, open the dialog
    setCurrentId(sectors[index].id);
    handleOpenDialog();
  }

  //New sector delete
  const handleDelete = (index: number) => {
    const newSectors = [...sectors];
    newSectors.splice(index, 1);
    setSectors(newSectors);
  }

  //Existing sector delete
  const handleApiDelete = async (id: number) => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/farm/sector/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        }
      );
      if (response.ok) {
        setSectors(prev => prev.filter(sector => sector.id !== id));
        showSnackbar('Sector eliminado correctamente', 'success');
      } else {
        showSnackbar('Error al eliminar sector', 'error');
      }
      handleCloseDialog();
    } catch (error: unknown) {
      showSnackbar('Error de conexión', 'error');
      handleCloseDialog();
    }
  }

  const isEmpty = (fieldValue: string) => !fieldValue.trim();

  return (
    <Grid container spacing={1}>
      {sectors.map((sector, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={11} sm={11}>
              <TextField
                fullWidth
                sx={{ backgroundColor: 'white' }}
                label={`Sector ${index + 1}`}
                value={sector.sectorName}
                onChange={(e) => handleNameChange(e, index)}
                error={isEmpty(sector.sectorName)}
                helperText={isEmpty(sector.sectorName) ? 'Campo requerido' : ''}
              />
            </Grid>
            {sectors.length > 0 && (
              <Grid item xs={1} sm={1}>
                <IconButton
                  onClick={() => handleDeleteConfirmation(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAdd}
        >
          Agregar sector
        </Button>
      </Grid>
      <DeleteSectorDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        onDelete={() => handleApiDelete(currentId)}
      />
    </Grid>
  );
}

export default SectorForm;
import React, { useState } from 'react';
import {
  Button,
  TextField,
  IconButton,
  Grid,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSubCompanyDialog from './DeleteSubCompanyDialog';
import { useAuth } from '../../context/authContext';
import { useSnackbar } from '../../context/snackbarContext';

interface SubCompany {
  id: number;
  subCompanyName: string;
  subCompanyRut: string;
}

interface SubCompaniesProps {
  subCompanies: SubCompany[];
  setSubCompanies: React.Dispatch<React.SetStateAction<SubCompany[]>>;
  isEditing: boolean;
}

const SubCompanies: React.FC<SubCompaniesProps> = ({ isEditing, subCompanies, setSubCompanies }) => {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number>(0);
  const token = auth?.authState.token;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newSubCompanies = [...subCompanies];
    newSubCompanies[index].subCompanyName = event.target.value;
    setSubCompanies(newSubCompanies);
  }

  const handleRutChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newRutValue = event.target.value.toUpperCase();
    const newSubCompanies = [...subCompanies];
    newSubCompanies[index].subCompanyRut = newRutValue;
    setSubCompanies(newSubCompanies);
  }

  const handleAdd = () => {
    const newSubCompanies = [...subCompanies];
    newSubCompanies.push({ id: 0, subCompanyName: '', subCompanyRut: '' });
    setSubCompanies(newSubCompanies);
  }

  //Dialog handling
  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  //Delete handling

  //Check if the subcompany is new or already exists
  const handleDeleteConfirmation = (index: number) => {
    if (subCompanies[index].id === 0) {
      handleDelete(index);
    } else {
      setCurrentId(subCompanies[index].id);
      handleOpenDialog();
    }
  }

  //New subcompany delete
  const handleDelete = (index: number) => {
    const newSubCompanies = [...subCompanies];
    newSubCompanies.splice(index, 1);
    setSubCompanies(newSubCompanies);
  }

  //Existing subcompany delete
  const handleApiDelete = async (id: number) => {
    try {
      const response = await fetch(
        `https://backend-iawdfmhnsa-uc.a.run.app/companies/subCompanies/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        }
      );
      if (response.ok) {
        setSubCompanies(prev => prev.filter(sc => sc.id !== id));
        showSnackbar('Razón social eliminada correctamente', 'success');
      } else {
        showSnackbar('Error al eliminar la razón social', 'error');
      }
      handleCloseDialog();
    } catch (error: unknown) {
      showSnackbar('Error de conexión', 'error');
      handleCloseDialog();
    }
  }

  //TODO: Move validations to a helper file
  const isValidRut = (rut: string) => {
    const rutRegex = /^\d+-[0-9kK]$/;
    return rutRegex.test(rut);
  }

  const isEmpty = (fieldValue: string) => !fieldValue.trim();

  return (
    <Grid container spacing={2}>
      {subCompanies.map((subCompany, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label={`Razón social ${index + 1}`}
                value={subCompany.subCompanyName}
                onChange={(e) => handleNameChange(e, index)}
                disabled={!isEditing}
                error={isEmpty(subCompany.subCompanyName)}
                helperText={isEmpty(subCompany.subCompanyName) ? 'Campo requerido' : ''}
              />
            </Grid>
            <Grid item xs={isEditing && subCompanies.length > 1 ? 11 : 12} sm={3}>
              <TextField
                fullWidth
                label={`RUT Razón social ${index + 1}`}
                value={subCompany.subCompanyRut}
                onChange={(e) => handleRutChange(e, index)}
                disabled={!isEditing}
                error={isEmpty(subCompany.subCompanyRut) || !isValidRut(subCompany.subCompanyRut)}
                helperText={isEmpty(subCompany.subCompanyRut) ? 'Campo requerido' : !isValidRut(subCompany.subCompanyRut) ? 'RUT inválido' : ''}
              />
            </Grid>
            {subCompanies.length > 1 && isEditing && (
              <Grid item xs={1} sm={2}>
                <IconButton
                  onClick={() => handleDeleteConfirmation(index)}
                  hidden={!isEditing}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      {isEditing && (
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAdd}
            hidden={!isEditing}
          >
            Agregar otra razón social
          </Button>
        </Grid>
      )}
      <DeleteSubCompanyDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        onDelete={() => handleApiDelete(currentId)}
      />
    </Grid>
  );
}

export default SubCompanies;
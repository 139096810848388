import * as React from "react";
import styles from "./CalendarInput.style.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";

interface ApplicationStep1Props {
  arrayPosition: number;
  upperText: string;
  defaultValue: string;
  setValueInArray: (value: string, position: number) => void;
}

export const CalendarInput = ({
  upperText,
  setValueInArray,
  arrayPosition,
  defaultValue,
}: ApplicationStep1Props) => {
  const [inputDefaultValue, setInputDefaultValue] = useState<string>();
  return (
    <>
      <div className={styles.textfieldContainer}>
        <p className={styles.upperText}>{upperText}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            format="DD-MM-YYYY"
            label={defaultValue}
            defaultValue={inputDefaultValue}
            onChange={(newValue) => {
              setInputDefaultValue(newValue === null ? "" : newValue);
              setValueInArray(newValue === null ? "" : newValue, arrayPosition);
            }}
          />
        </LocalizationProvider>
      </div>
    </>
  );
};

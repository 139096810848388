import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

interface SnackbarContextProps {
  showSnackbar: (message: string, severity: AlertColor) => void;
}

interface SnackbarMessage {
  message: string;
  severity: AlertColor;
  key: number;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined > (undefined);

export const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
}

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo){
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open){
      setOpen(false);
    }
  },[snackPack, messageInfo, open])

  const showSnackbar = useCallback((message: string, severity: AlertColor) => {
    setSnackPack((prev) => [...prev, { message, severity, key: new Date().getTime() }]);
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event, 
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  }

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        key={messageInfo?.key}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        <Alert 
          onClose={handleClose} 
          severity={messageInfo ? messageInfo.severity : 'info'} 
          sx={{ width: '100%' }}
        >
        {messageInfo ? messageInfo.message : ''}
      </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
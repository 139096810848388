import React, { useState } from 'react';
import { 
  Typography,
  Grid, 
} from '@mui/material';
import ProductDropdown from './ProductDropdown';
import ProductDetail from './ProductDetail';

interface Product{
  id: number;
  name: string;
  variety: string;
  imageUrl: string;
}

interface ProductData{
  id: number;
  block: string;
  variety: string;
  roles : string[];
  loteCodeRule: null | string;
  sproutsDescription: null | string;
  regulatedProduct: Product;
}

interface ProductFormProps{
  toggle: () => void;
  editingProduct: ProductData | null;
  fetchProducts: () => void;
}

const ProductForm: React.FC<ProductFormProps> = ({ toggle, editingProduct, fetchProducts }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  return (
    <>
      {editingProduct ? (
        <>
          <Grid item xs={12}>
            <Typography 
              variant='h6' 
              component='h2' 
              gutterBottom
              sx={{ 
                fontSize: '20px',
                color: '#093BA0',
                fontWeight: 'bold', 
              }}
            >
              Editar Producto
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* Regulated Product from editing product as prop*/}
            <ProductDetail product={editingProduct.regulatedProduct} toggle={toggle} editingProduct={editingProduct} fetchProducts={fetchProducts}/>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography 
              variant='h6' 
              component='h2' 
              gutterBottom
              sx={{ 
                fontSize: '20px',
                color: '#093BA0',
                fontWeight: 'bold', 
              }}
            >
              ¿Con qué productos trabajas?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ProductDropdown onProductSelect={setSelectedProduct} />
          </Grid>
          {selectedProduct && (
            <Grid item xs={12}>
              {/* Use selection as Regulated Product */}
              <ProductDetail product={selectedProduct} toggle={toggle} editingProduct={editingProduct} fetchProducts={fetchProducts} />
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default ProductForm;
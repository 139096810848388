interface newSector {
  farmId: number;
  sectorName: string;
}

interface Sector {
  id: number;
  sectorName: string;
}

export const sectorDelete = async (id: number, token: string | null | undefined) => {
  try {
    const response = await fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/farm/sector/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Error al eliminar el sector');
    }
  } catch (error: unknown) {
    throw error;
  }
}

export const sectorCreate = async (sector: newSector, token: string | null | undefined) => {
  try {
    const response = await fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/farm/sector`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(sector),
      }
    );
    if (!response.ok) {
      throw new Error('Error al crear el sector');
    }
  } catch (error: unknown) {
    throw error;
  }
}

export const sectorUpdate = async (sector: Sector, token: string | null | undefined) => {
  try {
    const response = await fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/farm/sector/${sector.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(sector),
      }
    );
    if (!response.ok) {
      throw new Error('Error al actualizar el sector');
    }
  } catch (error: unknown) {
    throw error;
  }
}
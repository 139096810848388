import { Box, Typography, useTheme } from '@mui/material';

export const HeroSectionBodyIconsUs: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      id='Nosotros'
      sx={{
        position: 'relative',
        height: '100vh',
        backgroundColor: '#e6ebf5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          height: '150vh',
        },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '800px',
          p: 10,
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            p: 2,
          },
        }}
      >
        <Typography
          variant='h3'
          component='div'
          sx={{
            my: 2,
            color: '#093BA0',
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 20,
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5rem',
              mb: 10,
            },
          }}
        >
          Quiénes somos
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 15,
            mt: 10,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: 10,
            },
          }}
        >
          <img src='img/JP.png' alt='JP' />
          <img src='img/CO.png' alt='CO' />
          <div>
            <Box
              sx={{
                mt: -15,
                [theme.breakpoints.down('sm')]: {
                  mt: 0,
                },
              }}
            >
              <img src='img/ArayaCía.jpeg' alt='MA' height={100} width={300} />
            </Box>
            <Typography
              sx={{
                color: '#093BA0',
                fontWeight: 'bold',
                mt: 8,
                fontSize: 20,
                [theme.breakpoints.down('sm')]: {
                  mt: 5,
                },
              }}
            >
              En alianza con
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

import { HomeNavbar } from "../components/HomeNavbar";
import { TraceabilityPage } from "../components/traceability/TraceabilityPage";

export const Traceability = () => {
  return (
    //TODO: se debe hacer un componente mas factorizado en el futuro para que no sea tan redundante los archivos
    <>
      {<HomeNavbar />}
      <TraceabilityPage />
    </>
  );
};

import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

export const HeroSectionBodyContact: React.FC = () => {
  const theme = useTheme();
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: '',
    company: '',
    phone: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch(`https://backend-iawdfmhnsa-uc.a.run.app/users/ContactUs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then((res) => {
        if (res.ok) {
          alert('Mensaje enviado');
        }
      })
      .catch((error) => {
        alert('Error al enviar el mensaje');
        console.error('Error send contact:', error);
      });
  };

  return (
    <Box
      id='contact'
      sx={{
        position: 'relative',
        height: '80vh',
        backgroundColor: '#e6ebf5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant='h3'
        component='div'
        sx={{
          my: 2,
          color: '#093BA0',
          fontWeight: 'bold',
          textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
          },
        }}
      >
        Trabajemos juntos
      </Typography>
      <Box
        component='form'
        onSubmit={handleSubmit}
        noValidate
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
          width: { xs: '80%', sm: '70%', md: '90%' },
          maxWidth: '900px',
          borderRadius: '10px',
          backgroundImage:
            'linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4))',
        }}
      >
        <Typography
          variant='h4'
          component='div'
          sx={{ mb: 5, mt: 5, fontWeight: 'bold' }}
          color='#093BA0'
        >
          Formulario de contacto
        </Typography>

        <TextField
          margin='normal'
          required
          fullWidth
          id='name'
          label='Nombre'
          name='name'
          value={contact.name}
          onChange={handleChange}
          sx={{ width: '60%' }}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          id='company'
          label='Empresa'
          name='company'
          value={contact.company}
          onChange={handleChange}
          sx={{ width: '60%' }}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          label='Correo Electrónico'
          name='email'
          value={contact.email}
          onChange={handleChange}
          sx={{ width: '60%' }}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          id='phone'
          label='Celular'
          name='phone'
          value={contact.phone}
          onChange={handleChange}
          sx={{ width: '60%' }}
        />

        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{
            mt: 3,
            mb: 2,
            width: '30%',
            borderRadius: '20px',
            backgroundColor: '#093BA0',
          }}
        >
          Enviar
        </Button>
      </Box>
    </Box>
  );
};

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useState, FormEvent } from "react";
import { useAuth } from "../context/authContext";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Logofsk } from "../icons/logofsk";
import LockIcon from "@mui/icons-material/Lock";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//TODO implementar variables de entorno
// const backendUrl = process.env.REACT_APP_BACKEND_URL ?? '';
export const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState("");

  const auth = useAuth();

  if (!auth) throw new Error("Auth context is undefined");

  const { loginUser } = auth;

  const handleSetUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    let username = e.target.value.toLowerCase();
    setUsername(username);
  };

  const handleSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClearForm = () => {
    setUsername("");
    setPassword("");
    setError("");
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      //   const url = backendUrl.replace('undefined', '') + 'users/login';
      const response = await fetch(
        "https://backend-iawdfmhnsa-uc.a.run.app/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: username, password: password }),
        }
      );
      if (!response.ok) {
        setError("Error al iniciar sesión, intente de nuevamente");
        throw new Error("Error en el login");
      }

      const data = await response.json();

      if (!data) {
        setError("Error al iniciar sesión, intente de nuevamente");
        throw new Error("Error en el login");
      }

      const user = {
        id: data.id,
        name: data.email,
        email: data.email,
        company: data.company,
        companyName: data.companyName,
      };
      localStorage.setItem("authToken", data.access_token);
      loginUser({ user: user, token: data.access_token });
      navigate("/menu");
    } catch (error) {
      handleClearForm();
      setError("Error de autenticación");
    } finally {
      setIsLoading(false);
      setIsForgotPassword(false);
    }
  };

  //no maneja errores de autenticacion ya que no revela si el correo existe o no, por seguridad
  const handleForgotPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const response = await fetch(
        "https://backend-iawdfmhnsa-uc.a.run.app/users/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: username }),
        }
      );
      if (!response.ok) {
        setError("Error al recuperar contraseña, intente de nuevamente");
        throw new Error("Error en el login");
      }
    } catch (error) {
      setError("Error de autenticación");
    } finally {
      setIsLoading(false);
      handleClearForm();
      setIsForgotPassword(false);
    }
  };

  //TODO: Refactorizar el formulario de login y el de recuperar contraseña
  return (
    <>
      <Grid
        container
        sx={{
          height: "100vh",
          width: "100%",
          bgcolor: "#093BA0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!isForgotPassword ? (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 600,
            }}
          >
            <Logofsk />
            <TextField
              required
              label="Correo electrónico"
              variant="outlined"
              sx={{
                width: "100%",
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                  input: { color: "white" },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
              }}
              value={username}
              onChange={handleSetUsername}
            />
            <TextField
              required
              label="Contraseña"
              variant="outlined"
              sx={{
                marginTop: 2,
                width: "100%",
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                  input: { color: "white" },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
              }}
              type="password"
              value={password}
              onChange={handleSetPassword}
            />
            {error.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: 1,
                  color: "red",
                  borderRadius: 5,
                  m: 2,
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "red", m: 2 }}
                >
                  {error}
                </Typography>
              </Box>
            )}
            {isLoading ? (
              <Button type="submit" disabled sx={{ mt: 2 }}>
                <CircularProgress size={30} />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100%",
                  backgroundColor: "white",
                  color: "#093BA0",
                  "&:hover": {
                    backgroundColor: "whiteSmoke",
                  },
                }}
              >
                Entrar
              </Button>
            )}
            <Button
              variant="text"
              sx={{
                color: "white",
                "&:hover": {
                  color: "whiteSmoke",
                },
                fontSize: 10,
                fontWeight: "lighter",
                marginLeft: 15,
                marginTop: 5,
              }}
              onClick={() => {
                setIsForgotPassword(true);
                handleClearForm();
              }}
            >
              <LockIcon /> recuperar contraseña
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 700,
            }}
            component="form"
            onSubmit={handleForgotPassword}
          >
            <Logofsk />
            <Typography variant="h5" color="whitesmoke" sx={{ mb: 2 }}>
              Recuperar contraseña
            </Typography>
            <Typography variant="body2" color="whitesmoke" sx={{ mb: 3 }}>
              Ingresa tu correo electrónico para recuperar tu contraseña
            </Typography>
            <TextField
              required
              label="Correo electrónico"
              variant="outlined"
              sx={{
                width: "100%",
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                  input: { color: "white" },
                },
                "& .MuiOutlinedInput-root .Mui-focused .MuiInputLabel-root": {
                  color: "white",
                },
              }}
              value={username}
              onChange={handleSetUsername}
            />
            {isLoading ? (
              <Button type="submit" disabled sx={{ mt: 2 }}>
                <CircularProgress size={30} />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  width: "100%",
                  backgroundColor: "white",
                  color: "#093BA0",
                  "&:hover": {
                    backgroundColor: "whiteSmoke",
                  },
                }}
              >
                Recuperar contraseña
              </Button>
            )}
            <Button
              variant="text"
              sx={{
                color: "white",
                "&:hover": {
                  color: "whiteSmoke",
                },
                fontSize: 10,
                fontWeight: "lighter",
                marginTop: 5,
              }}
              onClick={() => {
                setIsForgotPassword(false);
                handleClearForm();
              }}
            >
              <ArrowBackIcon /> volver al login
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );
};

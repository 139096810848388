import * as React from "react";
import styles from "./StandardInput.style.module.css";
import { TextField } from "@mui/material";

interface ApplicationStep1Props {
  setValue: (value: string) => void;
  upperText: string;
  setValueInArray: (value: string, position: number) => void;
  arrayPosition: number;
  label?: string;
  defaultValue: string;
}

export const StandardInput = ({
  setValue,
  upperText,
  setValueInArray,
  arrayPosition,
  label,
  defaultValue,
}: ApplicationStep1Props) => {
  const [inputValue, setInputValue] = React.useState(defaultValue);
  return (
    <>
      <div className={styles.textfieldContainer}>
        <p className={styles.upperText}>{upperText}</p>
        <TextField
          id="outlined-basic"
          label={label}
          variant="outlined"
          onChange={({ target: { value } }) => {
            setValueInArray(value, arrayPosition);
            setInputValue(value);
          }}
          value={inputValue}
        />
      </div>
    </>
  );
};

import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Hidden,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import { useAuth } from '../../context/authContext';
import DeleteProductDialog from './DeleteProductDialog';
import { useSnackbar } from '../../context/snackbarContext';

interface Product {
  id: number;
  name: string;
  variety: string;
  imageUrl: string;
}

interface ProductData {
  id: number;
  block: string;
  variety: string;
  roles: string[];
  loteCodeRule: null | string;
  sproutsDescription: null | string;
  regulatedProduct: Product;
}

interface ProductDetailProps {
  product: Product;
  toggle: () => void;
  editingProduct: ProductData | null;
  fetchProducts: () => void;
}

const ImageBox = styled(Box)({
  width: '35%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const ProductDetail: React.FC<ProductDetailProps> = ({ product, toggle, editingProduct, fetchProducts }) => {
  const theme = useTheme();
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [block, setBlock] = useState<string>('');
  const [variety, setVariety] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [description1, setDescription1] = useState<string>('');
  const [description2, setDescription2] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const companyId = auth?.authState.user?.company;
  const token = auth?.authState.token;

  useEffect(() => {
    // Set form data if editing a product
    if (editingProduct) {
      setBlock(editingProduct.block);
      setVariety(editingProduct.variety);
      setRoles(editingProduct.roles);
      setDescription1(editingProduct.loteCodeRule || '');
      setDescription2(editingProduct.sproutsDescription || '');
    } else {
      handleCleanForm();
    }
  }, [editingProduct]);

  useEffect(() => {
    //Form validation
    const checkFormValidity = () => {
      const isValid =
        block.trim() !== '' && variety.trim() !== '' && roles.length > 0;
      setIsFormValid(isValid);
    };

    checkFormValidity();
  }, [block, variety, roles]);

  //Role options, hardcoded for now maybe
  const opcionesRoles = [
    'Cosecha',
    'Empaca',
    'Enfría',
    'Recibe',
    'Recibe de Buque en tierra',
    'Despacha',
    'Transformador',
  ];


  //Form handling
  const handleBlockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlock(event.target.value);
  };

  const handleVarietyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariety(event.target.value);
  };

  const handleRolesChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setRoles(value as string[]);
  };

  const handleDescription1Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription1(event.target.value);
  };

  const handleDescription2Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription2(event.target.value);
  };

  //Clear form data
  const handleCleanForm = () => {
    setBlock('');
    setVariety('');
    setRoles([]);
    setDescription1('');
    setDescription2('');
  };


  //Send form data to backend
  const handleSubmit = async () => {
    // Check if form is valid
    if (!isFormValid) {
      showSnackbar('Por favor, completa todos los campos requeridos.', 'error');
      return;
    }

    // Payload to send to the server
    const payload = {
      block,
      variety,
      regulatedProductId: product.id,
      companyId,
      roles,
      loteCodeRule: description1, // Optional
      sproutsDescription: description2, // Optional
    };

    const baseURL = 'https://backend-iawdfmhnsa-uc.a.run.app/companyProducts';
    const url = `${baseURL}${editingProduct ? `/${editingProduct.id}` : ''}`; // Add product id to the URL if it's an edit

    const method = editingProduct ? 'PATCH' : 'POST'; // If it's a product edit, use PATCH, else use POST

    try {
      setIsSubmitting(true);
      const response = await fetch(url,
        {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + token,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error('La solicitud al servidor falló');
      }

      editingProduct ?
        showSnackbar('Producto editado con éxito', 'success')
        : showSnackbar('Producto agregado con éxito', 'success');
      handleCleanForm();
      toggle();
      fetchProducts();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
      }
      showSnackbar('Ocurrió un error al enviar los datos.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  //Dialog handling
  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  //Handle product deletion
  const handleDelete = async () => {
    if (editingProduct) {
      try {
        setIsSubmitting(true);
        const response = await fetch(
          `https://backend-iawdfmhnsa-uc.a.run.app/companyProducts/${editingProduct.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + token,
            },
          }
        );
        if (!response.ok) {
          throw new Error('La solicitud al servidor falló');
        }
        showSnackbar('Producto eliminado con éxito', 'success');
        toggle();
        fetchProducts();
      } catch (error) {
        console.error('Error al eliminar el producto: ', error);
        showSnackbar('Ocurrió un error al eliminar el producto.', 'error');
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  //TODO: Refactor for better readability
  return (
    <Grid
      container
      spacing={2}
      lg={11.7}
      sx={{
        backgroundColor: '#F4F4F4',
        margin: 2,
        paddingRight: 2,
        paddingBottom: 4,
      }}
    >
      <Hidden smDown>
        <Grid item md={2}>
          <ImageBox style={{ backgroundImage: `url(${product.imageUrl})` }} />
        </Grid>
      </Hidden>
      <Grid
        item
        sm={12}
        md={4}
        sx={{ [theme.breakpoints.up('md')]: { marginLeft: '-110px' } }}
      >
        <Typography
          variant='h6'
          component='h3'
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          {product.name}
        </Typography>
        <TextField
          required
          fullWidth
          label='Cuartel'
          placeholder='Escribir cuartel'
          value={block}
          onChange={handleBlockChange}
          margin='normal'
          sx={{ backgroundColor: 'white' }}
        />
        <TextField
          required
          fullWidth
          label='Variedades'
          placeholder='Escribir variedades'
          value={variety}
          onChange={handleVarietyChange}
          margin='normal'
          sx={{ backgroundColor: 'white' }}
        />
        <FormControl required fullWidth margin='normal'>
          <InputLabel>Roles</InputLabel>
          <Select
            multiple
            value={roles}
            onChange={handleRolesChange}
            input={<OutlinedInput label='Roles' />}
            renderValue={(selected) => selected.join(', ')}
            placeholder='Elegir rol(es)'
            sx={{ backgroundColor: 'white' }}
          >
            {opcionesRoles.map((rol) => (
              <MenuItem key={rol} value={rol}>
                <Checkbox checked={roles.indexOf(rol) > -1} />
                <ListItemText primary={rol} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={12} md={6} sx={{ marginLeft: '30px' }}>
        <Box>
          <Typography variant='body1' gutterBottom>
            <strong>
              SOLO SI EMPACA, RECIBE DE BUQUE O TRANSFORMA alimentos de la lista
            </strong>{' '}
            a.3 Una descripción de{' '}
            <strong>cómo se asignan códigos de lote de trazabilidad</strong> a
            los alimentos en la Lista de Trazabilidad de Alimentos de acuerdo
            con §1.1320.
          </Typography>
          <TextField
            fullWidth
            placeholder='Escribe la descripción ...'
            value={description1}
            onChange={handleDescription1Change}
            margin='normal'
            multiline
            rows={4}
            sx={{ backgroundColor: 'white' }}
          />
        </Box>
        <Box>
          <Typography variant='body1' gutterBottom>
            <strong>b.5 (SOLO si producto es BROTES)</strong> Una descripción de
            las semillas, incluido el tipo o nombre taxonómico de las semillas,
            las especificaciones de crecimiento, el tipo de empaque y (si
            aplica) el tratamiento antimicrobiano;
          </Typography>
          <TextField
            fullWidth
            placeholder='Escribe la descripción ...'
            value={description2}
            onChange={handleDescription2Change}
            margin='normal'
            multiline
            rows={4}
            sx={{ backgroundColor: 'white' }}
          />
        </Box>
        <Box display='flex' justifyContent='flex-end' mt={2}>
          <Button
            variant='contained'
            sx={{
              marginRight: '40px',
              borderRadius: '30px',
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              color: '#000000',
              paddingX: '30px',
              textTransform: 'none',
              fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: 'rgb(255, 255, 255, 0.7)',
                borderColor: 'transparent',
                color: '#000000',
              },
            }}
            disabled={isSubmitting}
            onClick={editingProduct ? handleOpenDialog : toggle}
          >
            {editingProduct ? 'Eliminar' : 'Cancelar'}
          </Button>
          <Button
            variant='contained'
            sx={{
              borderRadius: '30px',
              backgroundColor: '#093BA0',
              color: '#FFFFFF',
              paddingX: '30px',
              textTransform: 'none',
              fontSize: '1.1rem',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                color: '#093BA0',
              },
            }}
            disabled={!isFormValid || isSubmitting}
            onClick={handleSubmit}
          >
            {editingProduct ? 'Guardar cambios' : 'Agregar'}
          </Button>
        </Box>
      </Grid>
      <DeleteProductDialog
        isOpen={openDialog}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
    </Grid>
  );
};

export default ProductDetail;

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useNavigate } from "react-router-dom";
import { Logofsk } from "../icons/logofsk";
import { Box, useTheme } from "@mui/system";
import { OverridableComponent } from "@mui/types";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  useMediaQuery,
} from "@mui/material";
import { useMemo, useState } from "react";

const buttonStyle = {
  color: "#A6A6A6",
  borderRadius: "4px",
  margin: 1,
  "&:hover": {
    backgroundColor: "#093BA0",
    color: "white",
  },
};

const specialButtonStyle = {
  ...buttonStyle,
  color: "white",
  bgcolor: "#093BA0",
  "&:hover": {
    backgroundColor: "#white",
    color: "#093BA0",
  },
};

interface NavbarButtonProps {
  to: string;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  isSpecial?: boolean;
}

// this is the button for the navbar, it has a special style for the last button in the navbar #is possible to export this component to use it in other parts of the app
const NavbarButton: React.FC<NavbarButtonProps> = ({
  to,
  label,
  icon: Icon,
  isSpecial = false,
}) => {
  const navigate = useNavigate();
  return (
    <Button
      sx={isSpecial ? specialButtonStyle : buttonStyle}
      onClick={() => navigate(to)}
    >
      {Icon && <Icon sx={{ mr: 1 }} />} {label}
    </Button>
  );
};

// this is the navbar for the home page
export const HomeNavbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const buttons = useMemo(() => {
    return [
      { to: "/menu", label: "Inicio", icon: HomeOutlinedIcon },
      { to: "/farms", label: "Mis Fincas", icon: LocationOnOutlinedIcon },
      { to: "/products", label: "Mis Productos" },
      { to: "/traceability", label: "Seguimiento", icon: CalendarMonthIcon },

      { to: "/profile", label: "Mi perfil", icon: Person2OutlinedIcon },
    ];
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // #TODO: inprove the style of the drawer
  const drawer = (
    <List>
      {buttons.map(({ to, label, icon: Icon }) => (
        <ListItem
          button
          key={label}
          onClick={() => {
            navigate(to);
            handleDrawerToggle();
          }}
        >
          {Icon && (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText primary={label} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <AppBar color="inherit" elevation={1}>
      <Toolbar>
        <Box component="div" sx={{ flexGrow: 1 }}>
          <Logofsk color={"#093BA0"} height="100" width="100" />
        </Box>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          buttons.map((button) => <NavbarButton key={button.to} {...button} />)
        )}
      </Toolbar>
    </AppBar>
  );
};

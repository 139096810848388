import * as React from "react";
import styles from "./TraceabilityPage.style.module.css";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

export const TraceabilityPage = () => {
  const auth = useAuth();
  const companyId = auth?.authState.user?.company;
  const token = "Bearer " + auth?.authState.token;
  const [formsDataFormatted, setformsDataFormatted] = useState([
    {
      id: "1", //Nro de lote
      lotNumber: "",
      regulation: "nodata", //regulacion
      productName: "", //nombre de producto
      stage: "", //etapa
      property: "", //finca
      businessName: "", //razon social
      status: "", //status
      dateOfCreation: "", //Creado
      //document: "", //documento
    },
  ]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clickedFiltre, setclickedFiltre] = useState("Todas"); //for later use, working code
  const [arrayOfRows, setarrayOfRows] = useState(formsDataFormatted);
  const [applicationsData, setApplicationsData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      `https://backend-iawdfmhnsa-uc.a.run.app/form-applications/company/${companyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) =>{ setformsData(data);setApplicationsData(data)})
      .catch((error) => {
        console.error("Error fetching farms:", error);
      });
  }, [companyId, token]);

  const setformsData = (data: any) => {
    let result: any = [];
    // eslint-disable-next-line array-callback-return
    data.map((index: any) => {
      result.push({
        id: index?.id, //ID unico del doc
        lotNumber: index["1_1320a"], //nro de lote
        regulation: "Estados Unidos Regla 204", //regulacion
        productName: index?.companyProduct?.regulatedProduct?.name, //nombre de producto
        stage: index?.rol1, //etapa
        property: index["1_1315a5"], //finca
        businessName: index?.subCompany?.subCompanyName, //razon social
        status:
          index?.companyProduct?.status === "FINISHED" ? "Finalizada" : "", //status
        dateOfCreation: index?.createdAt?.slice(0, 10), //Creado
        Application: "Formulario",
        //document: "Descargar", //documento
      });
    });
    setformsDataFormatted(result);
    setarrayOfRows(result);
  };

  const columns2: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      //flex: 1,
      renderCell: RenderID,
      headerClassName: "theme--header",
    },
    {
      field: "lotNumber",
      headerName: "Lote",
      flex: 1,
      editable: false,
      renderCell: RenderLotNumber,
      headerClassName: "theme--header",
    },
    {
      field: "regulation",
      headerName: "Regulación",
      minWidth: 210,
      flex: 1,
      //: largeScreen ? 220 : MediumScreen ? 200 : smallScreen ? 180 : 180,
      editable: false,
      headerClassName: "theme--header",
    },
    {
      field: "productName",
      headerName: "Producto",
      flex: 1,
      minWidth: 120,
      editable: false,
      headerClassName: "theme--header",
    },
    {
      field: "stage",
      headerName: "Etapa",
      minWidth: 230,
      editable: false,
      headerClassName: "theme--header",
    },
    {
      field: "property",
      headerName: "Finca",
      flex: 1,
      minWidth: 100,
      editable: false,
      headerClassName: "theme--header",
    },
    {
      field: "businessName",
      headerName: "Razón social",
      flex: 1,
      minWidth: 120,
      editable: false,
      headerClassName: "theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: RenderStatus,
      headerClassName: "theme--header",
    },
    {
      field: "dateOfCreation",
      headerName: "Creado",
      editable: false,
      headerClassName: "theme--header",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "Application",
      headerName: "Ver",
      editable: false,
      headerClassName: "theme--header",
      minWidth: 100,
      maxWidth: 100,
      renderCell: RenderApplication,
    },
    /*{ //commenting download column for later use
      field: "document",
      headerName: "Documento",
      width: 110,
      flex: 1,
      editable: false,
      type: "c",
      headerClassName: "theme--header",
      renderCell: RenderDocument,
    },*/
  ];

  //for later use
  /*function RenderDocument(props: GridRenderCellParams<any>) {
    //for later use
    const innerValueOfRow = props?.row?.document;

    return (
      <>
        <p className={styles.downloadDocumentText}>{innerValueOfRow}</p>
        <DownloadRoundedIcon htmlColor="#001F3F" />{" "}
      </>
    );
  }*/

  function getCompanyProductID(arrayID:any) {
    let x=0;
    applicationsData.map((index: any) => {
      if (index?.id === arrayID){
        x= index?.companyProduct?.id
      }
      
    });
    return x;
  }

  function RenderID(props: GridRenderCellParams<any>) {
    const innerValueOfRow = props?.row?.id;

    return <p className={styles.lotIdText}>{innerValueOfRow}</p>;
  }

  function RenderLotNumber(props: GridRenderCellParams<any>) {
    const innerValueOfRow = props?.row?.lotNumber;

    return <p className={styles.lotIdText}>#{innerValueOfRow}</p>;
  }

  function RenderStatus(props: GridRenderCellParams<any>) {
    const innerValueOfRow = props?.row?.status;
    let productID = getCompanyProductID(props?.row?.id);
    if (innerValueOfRow === "En Proceso") {
      return (
        <div
          className={styles.blueState}
          onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
        >
          <p>{innerValueOfRow}</p>
        </div>
      );
    }
    if (innerValueOfRow === "Por Revisar") {
      return (
        <div
          className={styles.yellowState}
          onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
        >
          <p>{innerValueOfRow}</p>
        </div>
      );
    }
    if (innerValueOfRow === "Finalizada") {
      return (
        <div
          className={styles.orangeState}
          onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
        >
          <p>{innerValueOfRow}</p>
        </div>
      );
    }
    if (innerValueOfRow === "Aprobada") {
      return (
        <div
          className={styles.greenState}
          onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
        >
          <p>{innerValueOfRow}</p>
        </div>
      );
    }
    if (innerValueOfRow === "Rechazada") {
      return (
        <div
          className={styles.redState}
          onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
        >
          <p>{innerValueOfRow}</p>
        </div>
      );
    }
    return (
      <div
        className={styles.greyState}
        onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
      >
        <p>No encontrado</p>
      </div>
    );
  }

  function RenderApplication(props: GridRenderCellParams<any>) {
    let productID = getCompanyProductID(props?.row?.id);
    return (
      <div
        className={styles.goToFormButton}
        onClick={() => navigate(`/application/0/${props?.row?.id}/${productID}`)}
      >
        Visualizar
      </div>
    );
  }

  return (
    <div className={styles.traceabilityMainPageContainer}>
      <div className={styles.traceabilityTitlesContainer}>
        <p className={styles.traceabilityTitleText}>Seguimiento</p>
        <p className={styles.traceabilitySubtitleText}>
          Revisa la trazabilidad de tus lotes.
        </p>
      </div>
      {/*<div className={styles.filtresContainer}>
        {/**TO DO: esto se repite mucho, se pudiera hacer una estuctura mas resumida con un .map  }
        <div
          className={
            clickedFiltre === "Todas"
              ? styles.SingleFiltreClicked
              : styles.SingleFiltreUnclicked
          }
        >
          <p
            className={
              clickedFiltre === "Todas"
                ? styles.filtresTextClicked
                : styles.filtresTextUnclicked
            }
            onClick={() => {
              setarrayOfRows(formsDataFormatted);
              setclickedFiltre("Todas");
            }}
          >
            Todas
          </p>
        </div>

        <div
          className={
            clickedFiltre === "Aprobada"
              ? styles.SingleFiltreClicked
              : styles.SingleFiltreUnclicked
          }
        >
          <p
            className={
              clickedFiltre === "Aprobada"
                ? styles.filtresTextClicked
                : styles.filtresTextUnclicked
            }
            onClick={() => {
              setarrayOfRows(
                formsDataFormatted.filter(
                  (item: any) => item["status"] === "Aprobada"
                )
              );
              setclickedFiltre("Aprobada");
            }}
          >
            Aprobadas
          </p>
        </div>

        <div
          className={
            clickedFiltre === "En Proceso"
              ? styles.SingleFiltreClicked
              : styles.SingleFiltreUnclicked
          }
        >
          <p
            className={
              clickedFiltre === "En Proceso"
                ? styles.filtresTextClicked
                : styles.filtresTextUnclicked
            }
            onClick={() => {
              setarrayOfRows(
                formsDataFormatted.filter(
                  (item: any) => item["status"] === "En Proceso"
                )
              );
              setclickedFiltre("En Proceso");
            }}
          >
            En Proceso
          </p>
        </div>

        <div
          className={
            clickedFiltre === "Por revisar"
              ? styles.SingleFiltreClicked
              : styles.SingleFiltreUnclicked
          }
        >
          <p
            className={
              clickedFiltre === "Por revisar"
                ? styles.filtresTextClicked
                : styles.filtresTextUnclicked
            }
            onClick={() => {
              setarrayOfRows(
                formsDataFormatted.filter(
                  (item: any) => item["status"] === "Por Revisar"
                )
              );
              setclickedFiltre("Por revisar");
            }}
          >
            Por revisar
          </p>
        </div>

        <div
          className={
            clickedFiltre === "Finalizadas"
              ? styles.SingleFiltreClicked
              : styles.SingleFiltreUnclicked
          }
        >
          <p
            className={
              clickedFiltre === "Finalizadas"
                ? styles.filtresTextClicked
                : styles.filtresTextUnclicked
            }
            onClick={() => {
              setarrayOfRows(
                formsDataFormatted.filter(
                  (item: any) => item["status"] === "Finalizada"
                )
              );
              setclickedFiltre("Finalizadas");
            }}
          >
            Finalizadas
          </p>
        </div>

        <div
          className={
            clickedFiltre === "Rechazadas"
              ? styles.SingleFiltreClicked
              : styles.SingleFiltreUnclicked
          }
        >
          <p
            className={
              clickedFiltre === "Rechazadas"
                ? styles.filtresTextClicked
                : styles.filtresTextUnclicked
            }
            onClick={() => {
              setarrayOfRows(
                formsDataFormatted.filter(
                  (item: any) => item["status"] === "Rechazada"
                )
              );
              setclickedFiltre("Rechazadas");
            }}
          >
            Rechazadas
          </p>
        </div>
      </div>*/}
      <div className={styles.datagridOuterContainer}>
        <div className={styles.datagridContainer}>
          <DataGrid
            rows={arrayOfRows}
            columns={columns2}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            sx={{
              "& .theme--header": {
                backgroundColor: "#001F3F",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

interface ProductListItemProps {
  name: string;
}
export const ProductListItem = ({ name }: ProductListItemProps) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Inventory2OutlinedIcon />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

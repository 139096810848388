import React, { useState, useEffect } from "react";
import { FarmCard } from "../components/farm/FarmCard";
import { FarmForm } from "../components/farm/FarmForm";
import { HomeNavbar } from "../components/HomeNavbar";
import { Box, Grid, CircularProgress, Alert, Typography } from "@mui/material";
import { useAuth } from "../context/authContext";
import { useSnackbar } from "../context/snackbarContext";
import Divider from "@mui/material/Divider";
import AddOrCancelButton from "../components/farm/FarmAddButton";
import {
  sectorCreate,
  sectorDelete,
  sectorUpdate,
} from "../components/farm/sectorsService";

interface FarmData {
  id: string;
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  imageUrl: string;
  sectors: Sector[];
}

interface Sector {
  id: number;
  sectorName: string;
}

export const Farm: React.FC = () => {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const [farms, setFarms] = useState<FarmData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [editingFarm, setEditingFarm] = useState<FarmData | null>(null);
  const companyId = auth?.authState.user?.company;
  const token = auth?.authState.token;

  const toggleForm = () => {
    setFormVisible(!formVisible);
    setEditingFarm(null);
  };

  const handleEdit = (farm: FarmData) => {
    setEditingFarm(farm);
    setFormVisible(true);
  };

  useEffect(() => {
    const fetchFarms = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://backend-iawdfmhnsa-uc.a.run.app/farm/${companyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Error al obtener las fincas");
        }
        const data = await response.json();
        setFarms(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchFarms();
  }, [token, companyId]);

  const handleFormSubmit = async (formData: any, farmId?: string) => {
    formData.append("company", companyId);
    if (farmId) {
      formData.append("id", farmId);
    }

    const sectorsTemp = formData.has("sectors")
      ? JSON.parse(formData.get("sectors"))
      : null;
    formData.delete("sectors");

    const baseUrl = "https://backend-iawdfmhnsa-uc.a.run.app/farm";
    const url = farmId ? `${baseUrl}/${farmId}` : baseUrl;

    const method = farmId ? "PUT" : "POST";
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      if (!response.ok) throw new Error("Network response was not ok");

      let farm: FarmData = {} as FarmData;

      if (response.status === 201 || response.status === 200) {
        farm = await response.json();
        if (sectorsTemp) {
          for (let sector of sectorsTemp) {
            if (sector.id === 0) {
              const { id, ...newSector } = sector;
              await sectorCreate(
                { ...newSector, farmId: farm.id || farmId },
                token
              );
            } else {
              await sectorUpdate(sector, token);
            }
          }
        }
      }
      farm.sectors = sectorsTemp;
      setFarms((currentFarms) => {
        if (farmId) {
          return currentFarms.map((currentFarm) =>
            currentFarm.id === farm.id ? farm : currentFarm
          );
        } else {
          return [...currentFarms, farm];
        }
      });
      farmId
        ? showSnackbar("Finca actualizada correctamente", "success")
        : showSnackbar("Finca creada correctamente", "success");

      toggleForm();
    } catch (error: unknown) {
      if (error instanceof Error) {
        farmId
          ? showSnackbar("Error al actualizar la finca", "error")
          : showSnackbar("Error al crear la finca", "error");
      }
    }
  };

  //Handle farm deletion
  const handleDelete = async () => {
    if (editingFarm) {
      try {
        for (let sector of editingFarm.sectors) {
          await sectorDelete(sector.id, token);
        }
        const response = await fetch(
          `https://backend-iawdfmhnsa-uc.a.run.app/farm/${editingFarm.id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        if (response.status === 200) {
          setFarms((currentFarms) =>
            currentFarms.filter((farm) => farm.id !== editingFarm.id)
          );
        }
        showSnackbar("Finca eliminada correctamente", "success");
        toggleForm();
      } catch (error: unknown) {
        if (error instanceof Error) {
          showSnackbar("Error al eliminar la finca", "error");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1, m: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} marginBottom={10}>
          <HomeNavbar />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            component="h1"
            alignItems="center"
            textAlign="center"
          >
            Mis Fincas
          </Typography>
          <Typography
            variant="h6"
            component="h1"
            alignItems="center"
            textAlign="center"
            color="#A6A6A6"
          >
            Revisa tus fincas ingresadas
          </Typography>
          <Divider sx={{ mt: 1, mb: 1 }} />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
        {isLoading ? (
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          !error && (
            <Grid item xs={12}>
              {farms.map((farm: FarmData) => (
                <FarmCard
                  key={farm.id}
                  {...farm}
                  onEdit={() => handleEdit(farm)}
                />
              ))}
              {farms.length === 0 && (
                <Typography
                  variant="h6"
                  component="h1"
                  alignItems="center"
                  textAlign="center"
                  color="#A6A6A6"
                >
                  No hay fincas ingresadas
                </Typography>
              )}
            </Grid>
          )
        )}
        {!error && (
          <Grid item xs={12}>
            <AddOrCancelButton toggle={toggleForm} isActive={formVisible} />
          </Grid>
        )}

        {formVisible && (
          <Grid item xs={12}>
            <FarmForm
              toggle={toggleForm}
              onFormSubmit={handleFormSubmit}
              editingFarm={editingFarm}
              handleDelete={handleDelete}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

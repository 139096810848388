import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlaceIcon from '@mui/icons-material/Place';

interface FarmListItemProps {
  name: string;
}
export const FarmListItem = ({ name }: FarmListItemProps) => {
  return (
    <ListItem>
      <ListItemIcon>
        <PlaceIcon />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
};

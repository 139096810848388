let step4InputsArray: {
  key: string;
  input_id: number;
  input_type: number; //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
  upper_text: string;
  options: string[]; //only combobox has options. Textfield will be an empty array
  input_value: string;
  input_multiple_values: string[]; //only for multipleOptions type input
  input_label: string; //gray text insidde input
}[] = [
  {
    key: "1_1330a2",
    input_id: 0,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.2 Fecha de recepción del alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
  },
  {
    key: "1_1330a3",
    input_id: 1,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.3 Cantidad y unidad de medida del alimento (p.ej., 75 contenedores, 200 Kilos)",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1330a4",
    input_id: 2,
    input_type: 3, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.4 Finca donde se cosechó",
    options: ["lugar1", "lugar2", "lugar3"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el lugar según mapa",
  },
  {
    key: "1_1330a8",
    input_id: 3,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.8 Fecha de cosecha del alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
  },
  {
    key: "1_1330a9",
    input_id: 4,
    input_type: 3, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.9 Lugar donde se enfrió",
    options: ["lugar1", "lugar2", "lugar3"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el lugar según mapa",
  },
  {
    key: "1_1330a10",
    input_id: 5,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.10 Fecha de enfriamiento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
  },
  {
    key: "1_1330a11",
    input_id: 6,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.11 Código del lote de trazabilidad",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el código del lote",
  },
  {
    key: "1_1330a12",
    input_id: 7,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.12 Descripción de producto empacado",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba la descripción",
  },
  {
    key: "1_1330a13",
    input_id: 8,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.13 La cantidad y la unidad de medida del alimento empacado (p.ej., 6 cajas, 25 contenedores de plástico reutilizables, 100 tanques, 200 libras);",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escribe la cantidad y unidad",
  },
  {
    key: "1_1330a14",
    input_id: 9,
    input_type: 3, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.4 Lugar donde se empacó",
    options: ["lugar1", "lugar2", "lugar3"],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el lugar según mapa",
  },
  {
    key: "1_1330a15",
    input_id: 10,
    input_type: 2, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text: "a.15 Fecha de empaque del alimento",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Seleccione la fecha",
  },
  {
    key: "1_1330a16",
    input_id: 11,
    input_type: 0, //0== textfield, 1 == combobox, 2==calendar, 3===multipleOptions
    upper_text:
      "a.16 Tipo de documento de referencia y el número de documento de referencia",
    options: [],
    input_value: "",
    input_multiple_values: [],
    input_label: "Escriba el tipo de documento y número",
  },
];

export default step4InputsArray;
